import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../HomePage.module.scss';
import stylesAL from './AdvantagesListing.module.scss';
import DivHtml from 'common/components/divHtml/DivHtml';

interface Items {
    title: string;
    description: string;
    image: string;
}

const AdvantagesListing: React.FC = () => {
    const { t } = useTranslation();

    const advantageList: Items[] = t('home.advantages.items', { returnObjects: true }) ?? [];

    return (
        <div className={stylesAL.advantagesContainer}>
            <div className={styles.sectionTitle}>{t('home.advantages.title').toUpperCase()}</div>
            <div className={stylesAL.advantageGridLayout}>
            {
                advantageList.map((item, key) => (
                    <div key={key} className={stylesAL.advantageItem}>           
                        <img src={item.image} className={stylesAL.advantageImage}/>
                        <div className={`${stylesAL.advantageDescriptionContainer}`}>
                            <DivHtml className={`${stylesAL.advantageTitle}`} html={item.title}/>
                            <div style={{ display: 'table-row' }}> <hr className={stylesAL.separator}/> </div>                     
                            <DivHtml className={`${stylesAL.advantageDescription}`} html={item.description}/>
                        </div>
                       
                    </div>
                ))
            }
            </div>
        </div>
    )
}

export default AdvantagesListing;
