import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './SplittedLayout.module.scss';
import useIsMobile from 'common/hooks/useIsMobile';

type Props = {
    leftSide?: React.ReactNode;
    children: React.ReactNode;
    leftSideNoPadding?: boolean;
}

const SplittedLayout: React.FunctionComponent<Props> = ({ leftSide, children, leftSideNoPadding }: Props) => {
    const isMobile = useIsMobile()

    return (       
           <div className={styles.container}>
                {leftSide && !isMobile && <div className={`${styles.leftMenu}`}>
                    {leftSide}
                </div>}

                <div className={styles.innerContainer}>
                    {children}
                </div>
            </div>                    
    );
};

export default SplittedLayout;
