import { UpdateUserDto } from './models/UpdateUserDto';
 import { POLICIES } from 'Config';
import Http from '../../common/services/Http';
import { Paged } from '../common/types/Page';
 import { UserSearchCriteria } from './models/UserSearchCriteria';
 import { UserDto, UsersSelectItemDto } from './models/UserDto';
import { RoleDto } from 'api/roles/models/RoleDto';
import { CheckAvailabilityDto } from 'api/common/models/CheckAvailabilityDto';
import { SelectValueLabel } from 'common/types/SelectValueLabel';
import CustomFile from 'common/models/CustomFile';

class UserService {
   
    public getList(criteria: UserSearchCriteria) {
        return Http.get<Paged<UserDto>>('users', criteria);
    }

    public getById(id: string) {
        return Http.get<UserDto>('users/' + id);
    }

    public getLoggedUser() {
        return Http.get<UserDto>('users/account');
    }

    public catalog() {
        return Http.get<SelectValueLabel[]>('users/catalog');
    }
  
    public create(model: UserDto, mediaFile: CustomFile | null) {
        return Http.postWithFile('users', model, mediaFile ? [mediaFile] : []);
    }

    public update(model: UserDto, mediaFile: CustomFile | null) {
        return Http.putWithFile('users', model, mediaFile ? [mediaFile] : []);
    }

    public updateAccount(model: UpdateUserDto, mediaFile: CustomFile | null) {
        return Http.putWithFile('users/account', model, mediaFile ? [mediaFile] : []);
    }

    public remove(model: UserDto) {
        return Http.put('users/deactivate', model);
    }

    public getRoles() {
        return Http.get<RoleDto[]>('users/get-all-roles');
    }

    public getAll() {
        return Http.get<UsersSelectItemDto[]>('users/get-All-for-select-item');
    }
    
    public hasPolicies(userPolicies: string[], policies: (keyof typeof POLICIES)[], type: 'OR' | 'AND' = 'AND') {
        const allowed = type === 'AND'
            ? (policies || []).map(p => (userPolicies || []).find(up => up === POLICIES[p]) ? true : false).filter(p => !p).length === 0 ? true : false
            : (policies || []).map(p => (userPolicies || []).find(up => up === POLICIES[p]) ? true : false).filter(p => p).length > 0 ? true : false;

        return allowed;
    }

    public checkAvailability(data: CheckAvailabilityDto) {
        return Http.get<boolean>('users/check-availability', data);
    }
}

export default new UserService();