
/**
 * API
 */
export const API_BASE_URL = process.env.REACT_APP_API_URL;
export const SERVER_BASE_URL = process.env.REACT_APP_SERVER_URL;

export const isTablet = (width: number) => {
    return (width <= 1200);
}

export const removeAccents = (str: string) => {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

/**
 * Storage
 */

export const STORAGE_KEY = 'cQfTjWnZr4u7x!A%D*G-KaNdRggUkXp2s';
export const STORAGE = {
    CURRENT_LOCALE: 'CURRENT_LOCALE',
    AUTH_USER_TOKEN: 'AUTH_USER_TOKEN',
    AUTH_USER_PROFILE: 'AUTH_USER_PROFILE',
};

/**
 * Logger
 */
export enum LOGGER_MESSAGE_TYPE {
    INFO = 1,
    ERROR,
}

export enum LOGGER_LOG_TYPE {
    REQUEST,
    APP_CRASH
}

export const LOGGER_PRINT_LOGS = process.env.REACT_APP_LOGGER_PRINT_LOGS || false;
export const LOGGER_PRINT_LOGS_LEVEL = LOGGER_MESSAGE_TYPE.INFO;

/**
 * POLICIES
 */
export const POLICIES = {
    SETTINGUP_USERS_READ: 'SETTINGUP_USERS_READ',
    SETTINGUP_USERS_WRITE: 'SETTINGUP_USERS_WRITE',
    SETTINGUP_ROLES_READ: 'SETTINGUP_ROLES_READ',
    SETTINGUP_ROLES_WRITE: 'SETTINGUP_ROLES_WRITE'
};

/**
 * POLICIES
 */
export const ROLES = {
    PLATFORM_ADMINISTRATOR: 'PLATFORM_ADMINISTRATOR',
    ADMINISTRATOR: 'ADMINISTRATOR',
    CONTRIBUTOR: 'CONTRIBUTOR'
};

/**
 * Pagination
 */
export const DEFAULT_PAGINATION_ITEMS_PER_PAGE = 10;
export const MAX_PAGINATION_ITEMS_PER_PAGE = 999999;

/**
 * DATE FORMAT
 */
export const DATE_FORMAT_DEFAUT = 'DD/MM/YYYY';
export const DATEPICKER_FORMAT_DEFAULT = 'dd/MM/yyyy';
export const DATE_TIME_FORMAT_DEFAUT = 'DD/MM/YYYY HH:mm';
export const DATE_FORMAT_MONTH_YEAR = 'MM/YYYY';
export const DATE_TIME_FORMAT_DEFAULT = 'DD/MM/YYYY | HH:mm';
export const DATE_FORMAT_MONTH_TEXT_YEAR = 'MMM/YYYY';
export const TIME_FORMAT_DEFAULT = 'HH:mm';

/**
 * Http errors
 */
export const HTTP_ERRORS = {
    UNAUTHORIZED: 401,
    CONFLICT: 409
};

/**
 * Languages
 */
export const DEFAULT_LANGUAGE = 'fr';
export const DEFAULTNS = 'translations';


export const FINALITY_TYPE = {
    SALE: 'SALE',
    FOLLOW: 'FOLLOW',
    SOLD: 'SOLD'
};