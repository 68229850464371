import { MENU_CLOSED_HEIGHT_SPACE, MENU_OPEN_HEIGHT_SPACE, MENU_OPEN_SCROLL_LIMIT } from "common/components/menu/Menu";

const navigateToElement = (url: string) => {
    if (!url.startsWith('#')) {
        return;
    }

    const el = document.getElementById(url.replace('#', ''));
    if (!el) {
        return;
    }
    
    const elementPosition = el.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.scrollY - (window.scrollY >= MENU_OPEN_SCROLL_LIMIT ? MENU_OPEN_HEIGHT_SPACE : MENU_CLOSED_HEIGHT_SPACE);

    window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
    });
}

export default navigateToElement;