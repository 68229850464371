import useTitle from 'common/hooks/useTitle';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './PartnersPage.module.scss';
import Header from 'common/components/header/Header';
import Button from 'common/components/button/Button';
import colors from 'styles/export/colors.module.scss';
import Footer from 'common/components/footer/Footer';
import ScrollToTop from 'common/components/scrollToTop/ScrollToTop';
import SendinBLue from 'common/components/sendinBlue/SendinBlue';
import SideSwappingList from 'common/components/sideSwappingList/SideSwappingList';
import Menu from 'common/components/menu/Menu';
import { useNavigate } from 'react-router-dom';
import navigateToElement from 'common/utils/navigateToElement';

const itemIdPrefix = 'section-item-';

const PartnersPage: React.FC = () => {
    const { t } = useTranslation();
    useTitle(t('partners.page_title', { appName: t('app.name') }));
    const navigate = useNavigate();
    
    const partners = t('partners.items', { returnObjects: true }) ?? [];

    const onClickMainButton = () => {
        const url = t('partners.header.button_url');
        if (!url) {
            return;
        }

        if (url.startsWith('#')) {
            navigateToElement(url);
            return;
        }

        navigate(url);
    }

    return (
        <div className={styles.container}>
            <ScrollToTop />
            <Menu /> 
            <Header
                title={t('partners.header.title', { interpolation: { escapeValue: false } })}
                subTitle={t('partners.header.description', { interpolation: { escapeValue: false } })}
                backgroundUrl={t('partners.header.background_url', { interpolation: { escapeValue: true } })}
                buttons={[
                    <Button
                        key={'main-button'}
                        color={colors.basicWhite}
                        textColor={colors.basicWhite}
                        onClick={onClickMainButton}
                    >
                        {t('partners.header.main_button').toUpperCase()}
                    </Button>
                ]}
            />
            
            <SideSwappingList items={partners} itemIdPrefix={itemIdPrefix} />

            <SendinBLue />
            <Footer/>
        </div>
    );
};

export default PartnersPage;
