import useTitle from "common/hooks/useTitle"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import styles from "./OurHouseDetailsPage.module.scss"
import Footer from "common/components/footer/Footer"
import ScrollToTop from "common/components/scrollToTop/ScrollToTop"
import { LOGGER_LOG_TYPE } from "Config"
import HousesService from "api/houses/HousesService"
import { HouseDto, HousePhaseDto, HouseTypologyDto } from "api/houses/models/HouseDto"
import Loading from "common/services/Loading"
import Logger from "common/services/Logger"
import { useToasts } from "react-toast-notifications"
import OurHouseDetailsHeader from "./ourHouseDetailsHeader/OurHouseDetailsHeader"
import { useNavigate, useParams } from "react-router-dom"
import HouseCover from "common/components/houseCover/HouseCover"
import HorizontalTimeline, { TimelineItem } from "common/components/horizontalTimeline/HorizontalTimeline"
import ParaticalInformation, { TypologyInfo } from "common/components/praticalInformation/ParaticalInformation"
import Lightbox from "react-18-image-lightbox";
import "react-18-image-lightbox/style.css";
import { ReactComponent as CubeIcon } from 'assets/svgs/cube.svg';
import { ReactComponent as Divider } from 'assets/svgs/divider.svg';
import OurHouseDescription from "./ourHouseDescription/OurHouseDescription"
import OurHouseDetails from "./ourHouseDetails/OurHouseDetails"
import SendinBlue from "common/components/sendinBlue/SendinBlue"
import HousesList from "common/components/housesList/HousesList"
import stylesDt from "./ourHouseDetails/OurHouseDetails.module.scss"
import useIsMobile from "common/hooks/useIsMobile"

const OurHousesPage: React.FC = () => {
	const { t } = useTranslation()
	useTitle(t("our_houses.page_title", { appName: t('app.name') }))
	const navigate = useNavigate();	
    const { addToast } = useToasts()
    const { id } = useParams<{ id?: string }>();
    const [house, setHouse] = useState<HouseDto | null>(null);
	const [houses, setHouses] = useState<HouseDto[]>([])
	const [openGallery, setOpenGallery] = useState<boolean>(false);
	const [imageIndex, setImageIndex] = useState<number>(0);
	const [images, setImages] = useState<string[]>([]);
	const [listingText, setListingText] = useState<{}>({});
	const isMobile = useIsMobile();

    useEffect(() => {
		void getData()
	}, [id])
    
    const getData = async () => {
		try {
			Loading.show();
            const house = id ? await HousesService.get(id) : null;
            setHouse(house);

			if (house?.attachments)
			{
				const imgs: string[] = [];
				house.attachments.filter(x => x.url!== house.mediaUrl).forEach(x => {
					imgs.push(x.url);
				})
				setImages(imgs);
			}

			const result = await HousesService.getList({ page: 1, itemsPerPage: 3, finalityType: house?.finalityType, orderColumn: 'created_date', orderBy: 'desc' });
            setHouses(result.items);

			if (result.items) {
				switch (house?.finalityType) {
					case 'FOLLOW':
						setListingText(t('our_houses.houses_listing.to_follow', { returnObjects: true }))
						break;
					case 'SALE':
						setListingText(t('our_houses.houses_listing.in_sell', { returnObjects: true }))
						break; 
					case 'SOLD':
						setListingText(t('our_houses.houses_listing.sold', { returnObjects: true }))
						break;
				}
			}

            Loading.hide()
		} catch (error) {
			Logger.error(LOGGER_LOG_TYPE.REQUEST, "Couldn't  get houses list", error)
			addToast(t("common.messages.error_load_info"), { appearance: "error" })
			Loading.hide()
		}
	}

    const renderTimeLine = (phases: HousePhaseDto[]) => (
		<HorizontalTimeline items={(phases.map(x => ({ name: x.name, complete: x.complete } as TimelineItem)))} />
	);

	const renderPraticalInfo = (typologies: HouseTypologyDto[], price?: number) => (
		<ParaticalInformation
			title={t("our_houses.pratical_information.title") ?? ''}
			buttonLabel={t('our_houses.pratical_information.button_more_title') ?? ''}
			onClick={() => { navigate(t('our_houses.pratical_information.button_more_url') ?? '#') }}
			price={price}
			typologies={(typologies.map(x => ({ name: x.name, icon: x.icon, value: x.value } as TypologyInfo)))}
		/>
	);

	return (
		<div className={styles.container}>
			<ScrollToTop />

			{!isMobile && <OurHouseDetailsHeader />}

			<HouseCover				
				style={{ height: '45rem' }}
				imageCoverUrl={images.length>0 ? images[0] : undefined}
				imageGalleryUrl={images.length>0 ? images[0] : undefined}
				textImageGallery={t("our_houses.houses_details.view_photos").toString()}
				onClickImageGallery={() => {					
					setOpenGallery(true);	
					setImageIndex(0);														
				}}
				icon3D={<CubeIcon />}
				link3D={house?.link3d ?? '#'}
				tooltipHtml3D={<div>{t("our_houses.houses_details.link3d_1")}<br />{t("our_houses.houses_details.link3d_2")}</div>}
			/>			

			{openGallery && images.length > 0 && (
				<Lightbox
					mainSrc={images[imageIndex]}
					nextSrc={images.length > 1 ? images[(imageIndex + 1) % images.length] : undefined}
					prevSrc={images.length > 1 ? images[(imageIndex + images.length - 1) % images.length] : undefined}
					onCloseRequest={() => {
						setOpenGallery(false);
						setImageIndex(0);
					}}
					onImageLoad={() => {
						window.dispatchEvent(new Event('resize'));
					}}
					onMovePrevRequest={() => setImageIndex((imageIndex + images.length - 1) % images.length)}
					onMoveNextRequest={() => setImageIndex((imageIndex + 1) % images.length)}
					enableZoom={false}
				/>
			)}

			<OurHouseDescription
				title={house?.title}
				location={house?.location}
				description={house?.description}
				praticalInfo={house && house.finalityType ==='SALE' && renderPraticalInfo(house.typologies ?? [], house.price)}
			/>

			{house && house.finalityType === 'FOLLOW' && house.phases && house.phases.length > 0 && renderTimeLine(house?.phases ?? [])}		

			<div className={styles.dividerContainer}>
				<Divider />
			</div>

			<OurHouseDetails details={house?.details}/>
			
			{houses.length > 0 && 
				<div className={`${stylesDt.container} ${styles.housesListingMarginTop}`}>
					<HousesList houses={houses} title={listingText}/>
				</div>
			}

			<SendinBlue />
			<Footer />
		</div>
	)
}

export default OurHousesPage
