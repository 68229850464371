import useTitle from 'common/hooks/useTitle';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './AboutPage.module.scss';
import Header from 'common/components/header/Header';
import Button from 'common/components/button/Button';
import colors from 'styles/export/colors.module.scss';
import Footer from 'common/components/footer/Footer';
import ScrollToTop from 'common/components/scrollToTop/ScrollToTop';
import SendinBlue from 'common/components/sendinBlue/SendinBlue';
import SideSwappingList from 'common/components/sideSwappingList/SideSwappingList';
import Menu from 'common/components/menu/Menu';
import { useNavigate } from 'react-router';
import navigateToElement from 'common/utils/navigateToElement';

const itemIdPrefix = 'section-item-';

const AboutPage: React.FC = () => {
    const { t } = useTranslation();
    useTitle(t('about.page_title', { appName: t('app.name') }));
    const navigate = useNavigate();
    const items = t('about.items', { returnObjects: true }) ?? [];

    const onClickMainButton = () => {
        const url = t('about.header.button_url');
        if (!url) {
            return;
        }

        if (url.startsWith('#')) {
            navigateToElement(url);
            return;
        }

        navigate(url);
    }

    return (
        <div className={styles.container}>
            <ScrollToTop />
            <Menu /> 
            <Header
                title={t('about.header.title', { interpolation: { escapeValue: false } })}
                subTitle={t('about.header.description', { interpolation: { escapeValue: false } })}
                backgroundUrl={t('about.header.background_url', { interpolation: { escapeValue: true } })}
                buttons={[
                    <Button
                        key={'main-button'}
                        color={colors.basicWhite}
                        textColor={colors.basicWhite}
                        onClick={onClickMainButton}
                    >
                        {t('about.header.main_button').toUpperCase()}
                    </Button>
                ]}
            />

            <SideSwappingList items={items} itemIdPrefix={itemIdPrefix} />
            <SendinBlue />
            <Footer/>
        </div>
    );
};

export default AboutPage;
