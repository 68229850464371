import { ReactComponent as CloseButton } from 'assets/svgs/x_navbar.svg';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { Reducers } from 'store/types';
import styles from './SideBarMenu.module.scss';
import { changeSideBar } from 'store/sideBar/action';
import { ReactComponent as Chevron } from 'assets/svgs/chevron_down.svg';
import { logout } from 'store/authentication/action';
import { UserProfile } from 'api/account/models/UserProfile';
import { ReactComponent as UserIcon } from 'assets/svgs/icon-user-client.svg';

export interface NavbarItemInfo {
    id: string;
    text: string;
    url: string;
}

const SidebarMenu: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState<boolean>(false
        )
    const isAuthenticated = useSelector<Reducers, boolean>(state => state.authentication.isAuthenticated);
    const user = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);

    const navbarItems: NavbarItemInfo[] = [
        { id: 'start', text: t('menu.start'), url: '/' },
        { id: 'about', text: t('menu.about'), url: '/about' },
        { id: 'our_houses', text: t('menu.our_houses'), url: '/our_houses' },
        { id: 'partners', text: t('menu.partners'), url: '/partners' },
        { id: 'contact', text: t('menu.contact'), url: '/contact' }
       
    ];

    const adminItems: NavbarItemInfo[] = [
        { id: 'users', text: t('admin.menu.users'), url: '/admin/users' },
        { id: 'houses', text: t('admin.menu.houses'), url: '/admin/houses' }
    ];

    const closeSideBar = () => {
        dispatch(changeSideBar());
    };

    const onLogoutClick = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        dispatch(logout());
        navigate('/');
    }
    
    return (
        <div className={styles.overflow}>
            <div className={styles.container}>
                <div className={styles.logoContainer}>
                    <div className={styles.button} onClick={() => { closeSideBar() }} >
                        <CloseButton />
                    </div>
                    <div> 
                        <img src={ t('logo.gray_url', { interpolation: { escapeValue: true } }) ?? '#'} />
                    </div>
                </div>
                <div className={styles.innerContainer}>
                     <div>
                        {navbarItems.map((item, key) => (
                            <div key={key} className={styles.item}> 
                                <NavLink
                                    to={item.url ?? '#'}
                                    className={({ isActive }) => (`${isActive ? styles.bold : ''} `)}
                                    onClick={() => { closeSideBar() }}>
                                    <div className={`${styles.text}`}>{item.text}</div>
                                    <hr/>
                                </NavLink>
                            </div>
                        ))}

                        {isAuthenticated && 
                        <>
                            <div className={styles.item} onClick={() => setIsOpen(!isOpen)}> 
                                <div className={styles.text}>
                                    {t('menu.backoffice')}
                                    <Chevron className={`${styles.chevron} ${isOpen ? styles.openChevron : null}`}/>
                                </div>
                                <hr/>
                            </div>
                            {isOpen && adminItems.map((item, key) => (
                                <div key={key} className={styles.item}>
                                     <NavLink
                                    to={item.url ?? '#'}
                                    className={({ isActive }) => (`${isActive ? styles.bold : ''} `)}
                                    onClick={() => { closeSideBar() }}>
                                        <div className={styles.text}>{item.text}</div>
                                    </NavLink>
                                </div>
                            ))}
                            <div className={styles.navLinks}>
                                {user?.mediaUrl
                                ? <img src={user.mediaUrl} className={styles.circularIcon} />
                                : <UserIcon
                                    className={`${styles.circularIcon}`}

                                />}
                                    <div>{user?.realName}</div>


                                <NavLink onClick={onLogoutClick} to={''} className={`${styles.navLinks}`}>
                                    {t('menu.logout')}
                                </NavLink>
                            </div>
                        </>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SidebarMenu;
