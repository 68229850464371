import styles from './HousesList.module.scss';
import { HouseDto } from 'api/houses/models/HouseDto';
import { ReactComponent as MarkerIcon } from 'assets/svgs/marker-icon.svg';
import NoImage from 'assets/imgs/empty_img.png';
import RenderSVG from 'common/components/renderSVG/RenderSVG';
import DivHtml from '../divHtml/DivHtml';
import { useNavigate } from 'react-router-dom';



interface Props {
    houses: HouseDto[];
    title: any;
    className?: string;
}


const HousesList = ({ houses, title, className }: Props) => {

    const navigate = useNavigate();
    // 
    return (
        <div className={`${className ?? ''}`}>
            <div className={styles.housesTitleDiv}>
                <DivHtml html={title.title?.toUpperCase() ?? ''} className={styles.sectionTitle}/>
                <div className={styles.housesSubTitle}>{title.description ?? ''}</div>
            </div>

            <div className={styles.housesGrid}>
                {houses.map((item, key) => (
                    <div key={key} className={styles.houseItem} onClick={() => navigate(`/our_houses/details/${item.id}`)}>
                        <div className={styles.houseImageContainer}> 
                            <img src={item.mediaUrl ?? NoImage} className={styles.houseImage}/>
                            {item.price && <div className={styles.housePriceContainer}>{item.price?.toLocaleString(undefined, { minimumFractionDigits: 0 })}€</div>}
                        </div>
                        <div className={styles.houseTitle}>
                            <DivHtml html={item.title?.toUpperCase() ?? ''} onlyText />
                        </div>
                        <div className={styles.houseInfoContainer}>
                            <div className={styles.houseLocationContainer}> 
                                <div>
                                    <MarkerIcon className={styles.markerIcon}/>
                                </div>
                                <span className={styles.houseLocation}>{item.location}</span>
                            </div>
                            <div className={styles.houseTypologies}>  
                                {item.typologies?.map((typologie, ii) => (
                                    <div className={styles.typologieItem} key={ii}>                   
                                        <RenderSVG item={typologie.icon} className={styles.icon}/>
                                        <div className={styles.typologieValue}>{typologie.value}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>                 
                ))}
            </div>
        </div>
    )
}

export default HousesList;