import Http from '../../common/services/Http';
import { type UserProfile } from './models/UserProfile';
import { type LoginViewModel } from './models/LoginViewModel';
import { type PasswordResetTokenViewModel } from './models/PasswordResetTokenViewModel';
import { type PasswordResetViewModel } from './models/PasswordResetViewModel';
import { type UserLogin } from './models/UserLogin';

class AccountService {
    
    public login(model: LoginViewModel) {
        return Http.post<UserLogin>('account/token', model);
    }

    public generateResetPasswordCode(model: PasswordResetTokenViewModel) {
        return Http.post('account/generate-reset-password-code', model);
    }

    public resetPassword(model: PasswordResetViewModel) {
        return Http.post<string>('account/reset-password', model);
    }

    public profile() {
        return Http.get<UserProfile>('account/profile');
    }
}

export default new AccountService();
