import { Paged } from 'api/common/types/Page';
import CustomFile from 'common/models/CustomFile';
import Http from 'common/services/Http';
import { HouseDto } from './models/HouseDto';
import { HouseSearchCriteria } from './models/HouseSearchCriteria';
import { TypologyDto } from './models/TypologyDto';
import { TypologySearchCriteria } from './models/TypologySearchCriteria';

class HousesService {
    public getList (criteria: HouseSearchCriteria) {
        return Http.get<Paged<HouseDto>>('houses/list', criteria);
    }

    public get (id: string) {
        return Http.get<HouseDto>('houses/' + id);
    }

    public create (model: HouseDto, attachments: CustomFile[]): Promise<string> {
        return Http.postWithFile('houses', model, attachments);
    }

    public update (model: HouseDto, attachments: CustomFile[]) {
        return Http.putWithFile('houses', model, attachments);
    }

    public remove (model: HouseDto) {
        return Http.put('houses/deactivate', model);
    }

    public getListTypologies (criteria: TypologySearchCriteria) {
        return Http.get<TypologyDto[]>('houses/list-typologies', criteria);
    }
}

export default new HousesService();