import React from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';

type Props = {
} & NumericFormatProps;

const MoneyFormat: React.FC<Props> = ({ value, suffix, ...props }: Props) => {

    return (
        <NumericFormat
            value={value}
            displayType={props.displayType || 'text'}
            thousandSeparator={' '}
            decimalSeparator={','}
            decimalScale={2}
            fixedDecimalScale={false}
            suffix={suffix ?? ' €'}
            {...props}
        />
    );
}

export default MoneyFormat;
