export default function dataURLtoFile (dataurl: string, filename: string): File | null {
    const arr = dataurl.split(',');
    const match = arr[0].match(/:([a-zA-Z0-9/_\-\\.]*?);/);
    if (!match || match.length < 1) {
        return null;
    }
    const mime = match[1];
    const bstr = atob(arr[1]);

    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
}
