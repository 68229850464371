import styles from './Header.module.scss';
import type Button from '../button/Button';
import DivHtml from '../divHtml/DivHtml';
import React from 'react';

interface Props {
    title: string
    subTitle: string;
    buttons: React.ReactElement<typeof Button>[];
    backgroundUrl: string;
};

const Header = ({ title, subTitle, buttons, backgroundUrl }: Props) => {
    return (
        <div className={`${styles.container}`}>
            <div className={styles.innerContainer} style={{ backgroundImage: `url(${backgroundUrl})` }}>
                <div className={styles.titleWrapper}>
                    <div className={styles.titleContainer}>
                        <DivHtml
                            html={title}
                            className={`${styles.title}`}
                        />
                        <DivHtml
                            html={subTitle}
                            className={`${styles.subTitle}`}
                        />
                        <div className={styles.headerButtonsContainer}>
                            {buttons}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;
