import React, { useState } from 'react';
import styles from './ToggleSwitch.module.scss';

interface Props {
    value: boolean;
    color: string;
    className?: string;
    disabled: boolean;
    onChange: (v: boolean) => void;
}

const ToggleSwitch: React.FC<Props> = ({ color, value: defaultValue, className, onChange, disabled }) => {
    const [value, setValue] = useState(defaultValue);

    return (
        <label className={`${styles.switch} ${className ?? ''}`}>
            <input
                type="checkbox"
                disabled={disabled}
                className={styles.input}
                defaultChecked={value}
                onChange={(e) => {
                    // onChange(e.target.checked);
                    setValue(e.target.checked);
                }}
            />
            <span
                className={styles.slider}
                onTransitionEnd={() => {
                    onChange(value);
                }}
                style={{
                    backgroundColor: value ? color : '',
                }}
            />
        </label>
    );
};

export default ToggleSwitch;
