import React, { CSSProperties, ReactElement, useState } from 'react';
import styles from './ListingTable.module.scss';
import { Property } from 'csstype'
import DateFormat from '../dateFormat/dateFormat';
import { useTranslation } from 'react-i18next';
import MoneyFormat from '../moneyFormat/MoneyFormat';
import { isTablet } from 'Config';
import { FaSortDown, FaSortUp, FaAngleDown, FaAngleUp, FaSort } from 'react-icons/fa';
import colors from 'styles/export/colors.module.scss';
import { IconType } from 'react-icons';
import HReafWrap from './hRefWrap/HRefWrap';

const SUBROWS_COLUMN_WIDTH = '10px';

export interface TableRow {
    [field: string]: any;
}

export type ListingTableCellFormat = 'default' | 'money' | 'date';

export interface ListingTableColumn<TRow, TParentRow = unknown> {
    field?: keyof TRow;
    name?: string | ReactElement;
    renderCell?: (row: TRow, column: ListingTableColumn<TRow, TParentRow>, index: number, parentRow?: TParentRow, parentRowIndex?: number) => ReactElement;
    cellAlignment?: Property.TextAlign;
    cellStyle?: CSSProperties;
    columnCellClassName?: string;
    columnCellAlignment?: Property.TextAlign;
    width?: string | number;
    preventClick?: boolean;
    cellFormat?: ListingTableCellFormat;
    cellFormatUnitByField?: string;
    hideOn?: string[]; // 'sm','md','lg','xl'
    onSearch?: (field: string, isFilterAsc: boolean) => void;
    searchField?: string;
    onlyImageColumn?: boolean;
}

interface Props<TRow, TSubRow = any> {
    columns: ListingTableColumn<TRow>[];
    subColumns?: ListingTableColumn<TSubRow, TRow>[];
    rows: TRow[];
    allowHover?: boolean;
    onRowClick?: (row: TRow, index: number, event: any) => void;
    onHref?: (row: TRow) => string;
    onSubRowClick?: (row: TSubRow, index: number, event: any) => void;
    striped?: boolean;
    selectedRow?: TRow | null;
    initialSearch?: SearchItem | null;
    showErrors?: boolean;
    errors?: any[];
    rowStyle?: (row: TRow, col: ListingTableColumn<TRow>, rowIndex: number, colIndex: number) => CSSProperties;
    subRowStyle?: (row: TSubRow, col: ListingTableColumn<TSubRow, TRow>, rowIndex: number, colIndex: number) => CSSProperties;
    className?: string;
    headerClassName?: string;
    hideHeaderWhenNoData?: boolean;
    isExpanded?: (row: TRow) => boolean;
    onLoadSubRows?: (row: TRow) => TSubRow[];
    colorRow?: string;
    colorSubRow?: string;
    hasSubRows?: boolean;
    onSubRowsLoadMore?: (row: TRow, index: number) => void;
    canSubRowsLoadMore?: (row: TRow, index: number) => boolean;
    labelSubRowWithoutResults?: string;
    allowSubRowHover?: boolean;
}

export interface SearchItem {
    colField: string;
    isOrderAsc: boolean;
}

const ListingTable = <TRow extends TableRow, TSubRow extends TableRow>({
    columns, subColumns, rows, selectedRow, allowHover = false, striped = false, className, headerClassName,
    initialSearch = null, showErrors = false, errors = [], onRowClick, onHref, onSubRowClick, rowStyle, subRowStyle, hideHeaderWhenNoData, isExpanded,
    onLoadSubRows, colorRow, colorSubRow, hasSubRows, onSubRowsLoadMore, canSubRowsLoadMore, labelSubRowWithoutResults, allowSubRowHover = false
}: Props<TRow, TSubRow>) => {

    const { t } = useTranslation();

    const getCellWidthStyle = (col: ListingTableColumn<TRow>) => {
        if (!col.width) {
            return {};
        }

        return { width: col.width };
    }

    const getSubCellWidthStyle = (col: ListingTableColumn<TSubRow, TRow>) => {
        if (!col.width) {
            return {};
        }

        return { width: col.width };
    }

    const getColumnCellStyle = (col: ListingTableColumn<TRow>) => {
        return {
            textAlign: col.columnCellAlignment || 'left',
            ...getCellWidthStyle(col),
        }
    }

    const getRowCellStyle = (col: ListingTableColumn<TRow>, row: TRow, rowIndex: number, colIndex: number): CSSProperties => {
        return {
            textAlign: col.cellAlignment || 'left',
            ...getCellWidthStyle(col),
            ...(col.cellStyle || {}),
            ...(rowStyle ? rowStyle(row, col, rowIndex, colIndex) : {}),
        }
    }

    const getSubRowCellStyle = (col: ListingTableColumn<TSubRow, TRow>, row: TSubRow, rowIndex: number, colIndex: number): CSSProperties => {
        return {
            textAlign: col.cellAlignment || 'left',
            ...getSubCellWidthStyle(col),
            ...(col.cellStyle || {}),
            ...(subRowStyle ? subRowStyle(row, col, rowIndex, colIndex) : {}),
        }
    }

    const getRowCellValue = (row: TRow, col: ListingTableColumn<TRow>, index: number) => {

        if (col.renderCell) {
            return col.renderCell(row, col, index);
        }

        if (!col.field) {
            return '';
        }

        const value = row[col.field];

        if (col.cellFormat && col.cellFormat === 'money') {
            return value != null ? <MoneyFormat value={value} suffix={col.cellFormatUnitByField ? row[col.cellFormatUnitByField] : null} /> : "-";
        }

        if (col.cellFormat && col.cellFormat === 'date') {
            return <DateFormat value={value} />;
        }

        return value;
    }

    const getSubRowCellValue = (row: TSubRow, col: ListingTableColumn<TSubRow, TRow>, index: number, parentRow: TRow, parentRowIndex: number) => {

        if (col.renderCell) {
            return col.renderCell(row, col, index, parentRow, parentRowIndex);
        }

        if (!col.field) {
            return '';
        }

        const value = row[col.field];

        if (col.cellFormat && col.cellFormat === 'money') {
            return <MoneyFormat value={value} suffix={col.cellFormatUnitByField ? row[col.cellFormatUnitByField] : null} />;
        }

        if (col.cellFormat && col.cellFormat === 'date') {
            return <DateFormat value={value} />;
        }

        return value;
    }

    const onClick = (event: any, row: TRow, col: ListingTableColumn<TRow>, index: number) => {
        if (col.preventClick) {
            return;
        }

        if (onRowClick) {
            onRowClick(row, index, event);
        }
    }

    const onSubClick = (event: any, row: TSubRow, col: ListingTableColumn<TSubRow, TRow>, index: number) => {
        if (col.preventClick) {
            return;
        }

        if (onSubRowClick) {
            onSubRowClick(row, index, event);
        }
    }

    const onSearch = (col: ListingTableColumn<TRow>) => {
        if (col.onSearch) {
            if (!isSearchActive) {
                col.onSearch(col.searchField ?? '', true);
                setIsSearchActive({ colField: col.searchField ?? '', isOrderAsc: true });
            } else {
                col.onSearch(col.searchField ?? '', isSearchActive.colField === col.searchField ? !isSearchActive.isOrderAsc : true);
                setIsSearchActive({ colField: col.searchField ?? '', isOrderAsc: isSearchActive.colField === col.searchField ? !isSearchActive.isOrderAsc : true });
            }
        }
    }

    const [isSearchActive, setIsSearchActive] = useState<SearchItem | null>(initialSearch);

    const isMobile = isTablet(window.innerWidth);

    const renderActiveFilterIcon = () => {
        return <div>
            {isSearchActive && !isSearchActive.isOrderAsc && <FaSortUp className={`${styles.headerInfoImage}`} />}
            {isSearchActive && isSearchActive.isOrderAsc && <FaSortDown className={`${styles.headerInfoImage}`} />}
        </div>
    }

    const renderHasFilterIcon = (col: ListingTableColumn<TRow>) => {
        return <div>
            {col.onSearch && <FaSort className={`${styles.headerInfoImage}`} />}
        </div>
    }

    const renderRowArrow = (Icon: IconType, rowIndex: number, row: TRow) => (
        <td
            key="col-arrow-row"
            style={{ width: SUBROWS_COLUMN_WIDTH }}
            className={`${row.onlyImageColumn ? styles.bodyColumnOnlyImage : styles.bodyColumn} ${striped && rowIndex % 2 === 0 ? styles.striped : ''}`}
            onClick={(event: any) => onRowClick && onRowClick(row, rowIndex, event)}
        >
            <Icon color={colors.gray} />
        </td>
    );

    const renderRow = (row: TRow, rowIndex: number) => (
        <tr key={`row-${rowIndex}`} style={{ boxShadow: showErrors && errors[rowIndex] != null ? 'rgb(255 0 0) 0px 0px 0px 2px inset' : '', background: colorRow ?? '' }} className={`${allowHover && styles.rowHover} ${(selectedRow && (selectedRow.id === row.id || selectedRow.index === row.index)) ? styles.selected : ''}`}>
            
            {hasSubRows && (isExpanded && isExpanded(row) ? renderRowArrow(FaAngleUp, rowIndex, row) : renderRowArrow(FaAngleDown, rowIndex, row))}
            {columns.map((col, colIndex) => (
                <td
                    key={`col-${colIndex}`}
                    onClick={event => onClick(event, row, col, rowIndex)}
                    className={`${col.hideOn && col.hideOn?.includes('xxl') ? styles.xxl : col.hideOn?.includes('xl') ? styles.xl : col.hideOn && col.hideOn?.includes('lg') ?
                        styles.lg : col.hideOn && col.hideOn?.includes('md') ? styles.md : col.hideOn && col.hideOn?.includes('sm') ?
                            styles.sm : ''} 
                                                ${col.onlyImageColumn ? styles.bodyColumnOnlyImage : styles.bodyColumn} ${striped && rowIndex % 2 === 0 ? styles.striped : ''}
                                                ${isMobile ? styles.mobile : ''}`}

                    style={getRowCellStyle(col, row, rowIndex, colIndex)}
                >
                    <HReafWrap href={onHref? onHref(row) : null}>{getRowCellValue(row, col, rowIndex)}</HReafWrap>
                </td>
                
            ))}
            
        </tr>
    );

    const renderSubRow = (row: TSubRow, rowIndex: number, parentRow: TRow, parentRowIndex: number) => (
        <tr key={`subrow-${rowIndex}`} 
            style={{ boxShadow: showErrors && errors[rowIndex] != null ? 'rgb(255 0 0) 0px 0px 0px 2px inset' : '', background: colorSubRow ?? '' }} 
            className={`${allowSubRowHover && styles.rowHover} ${(selectedRow && (selectedRow.id === row.id || selectedRow.index === row.index)) ? styles.selected : ''}`}
        >
            {hasSubRows && (<td key="col-sb" style={{ width: SUBROWS_COLUMN_WIDTH }} className={`${row.onlyImageColumn ? styles.bodyColumnOnlyImage : styles.bodyColumn} ${striped && rowIndex % 2 === 0 ? styles.striped : ''}`}></td>)}
            {(subColumns || []).map((col, colIndex) => (
                <td
                    key={`col-${colIndex}`}
                    onClick={event => onSubClick(event, row, col, rowIndex)}
                    className={` ${col.hideOn && col.hideOn?.includes('xxl') ? styles.xxl : col.hideOn?.includes('xl') ? styles.xl : col.hideOn && col.hideOn?.includes('lg') ?
                        styles.lg : col.hideOn && col.hideOn?.includes('md') ? styles.md : col.hideOn && col.hideOn?.includes('sm') ?
                            styles.sm : ''} 
                                                ${styles.bodySubColumn} ${striped && rowIndex % 2 === 0 ? styles.striped : ''}
                                                
                                                ${isMobile ? styles.mobile : ''}`}
                    style={getSubRowCellStyle(col, row, rowIndex, colIndex)}
                >
                    {getSubRowCellValue(row, col, rowIndex, parentRow, parentRowIndex)}
                </td>
            ))}
        </tr>
    );

    const renderSubRowLoadMore = (row: TRow, rowIndex: number) => (
        <tr key={`subrow-load-more-${rowIndex}`} style={{ boxShadow: showErrors && errors[rowIndex] != null ? 'rgb(255 0 0) 0px 0px 0px 2px inset' : '', background: colorSubRow ?? '' }} className={`${allowHover && styles.rowHover} ${(selectedRow && (selectedRow.id === row.id || selectedRow.index === row.index)) ? styles.selected : ''}`}>
            <td
                colSpan={(subColumns || []).length + 1}
                onClick={() => {
                    if (onSubRowsLoadMore) {
                        onSubRowsLoadMore(row, rowIndex)
                    }
                }}
                className={`${row.onlyImageColumn ? styles.bodyColumnOnlyImage : styles.bodyColumn} ${striped && rowIndex % 2 === 0 ? styles.striped : ''} ${isMobile ? styles.mobile : ''} ${styles.loadMoreRow}`}
            >
                {t('common.load_more')}
            </td>
        </tr>
    )

    const renderSubRowWithoutResults = (row: TRow, rowIndex: number) => (
        <tr key={`subrow-without-results-${rowIndex}`} className={` ${(selectedRow && (selectedRow.id === row.id || selectedRow.index === row.index)) ? styles.selected : ''}`}>
            <td
                colSpan={(subColumns || []).length + 1}
                className={`${row.onlyImageColumn ? styles.bodyColumnOnlyImage : styles.bodyColumn} ${striped && rowIndex % 2 === 0 ? styles.striped : ''} ${isMobile ? styles.mobile : ''} ${styles.subRowWithoutResults}`}
            >
                {labelSubRowWithoutResults ? labelSubRowWithoutResults : t('common.no_results')}
            </td>
        </tr>
    )

    return (
        <table className={`${styles.table} ${className || ''}`}>
            {(Boolean(rows.length > 0) || (Boolean(rows.length <= 0) && !hideHeaderWhenNoData)) && <thead>
                <tr>
                    {hasSubRows && (<th style={{ width: SUBROWS_COLUMN_WIDTH }}></th>
                    )}
                    {columns.map((col, colIndex) => (
                        <th key={`col-${colIndex}`} className={`${!col.hideOn ? '' :
                            col.hideOn?.includes('xxl') ? styles.xxl : col.hideOn?.includes('xl') ? styles.xl : col.hideOn?.includes('lg') ? styles.lg
                                : col.hideOn?.includes('md') ? styles.md : col.hideOn?.includes('sm') ? styles.sm : ''} 
                            ${col.onlyImageColumn ? styles.columnHeaderOnlyImage : styles.columnHeader} ${headerClassName}
                            ${col.onSearch && styles.clicakble}
                            ${col.columnCellClassName || ''}
                            `} style={getColumnCellStyle(col)}
                            onClick={() => onSearch(col)}>
                            <span className={`${styles.headerInfo} ${col.columnCellAlignment === 'right' ? styles.headerInfoRight : ''}`}>
                                {col.name} {isSearchActive && isSearchActive.colField === col.searchField ? renderActiveFilterIcon() : renderHasFilterIcon(col)}
                            </span>
                        </th>
                    ))}
                </tr>
            </thead>}
            <tbody>
                {rows.map((row, rowIndex) => {
                    const subrows = onLoadSubRows ? onLoadSubRows(row) : null;
                    const subRowsLoadMore = canSubRowsLoadMore && canSubRowsLoadMore(row, rowIndex);
                    return (
                        (<React.Fragment key={'fragment-row-'+rowIndex}>
                            {renderRow(row, rowIndex)}
                            {isExpanded && isExpanded(row) && subrows && <>
                                {subrows.map((subrow, subrowIndex) => renderSubRow(subrow, subrowIndex, row, rowIndex))}
                                {subRowsLoadMore && renderSubRowLoadMore(row, rowIndex)}
                            </>}
                            {isExpanded && isExpanded(row) && subrows?.length == 0 && renderSubRowWithoutResults(row, rowIndex)}
                        </React.Fragment>)
                    );
                })}
            </tbody>
            {Boolean(rows.length <= 0) && <tfoot>
                <tr>
                    <td colSpan={columns.length} className={styles.noItemsText}>
                        {t('common.no_results')}
                    </td>
                </tr>
            </tfoot>}
        </table>
    );
}

export default ListingTable;