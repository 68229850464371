import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import styles from './AdminMenuScreen.module.scss';
import { logout } from 'store/authentication/action';
import { useDispatch, useSelector } from 'react-redux';
import { UserProfile } from 'api/account/models/UserProfile';
import { Reducers } from 'store/types';
import { ReactComponent as UserIcon } from 'assets/svgs/icon-user-client.svg';
import useTitle from 'common/hooks/useTitle';

type Props = {
    menuItems: AdminMenuItem[];
}

export interface AdminMenuItem {
    text: string;
    url: string;
    isOpened?: boolean
}

const AdminMenuScreen: React.FunctionComponent<Props> = ({ menuItems }: Props) => {
    const { t } = useTranslation();
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useTitle(t('admin.page_title', { appName: t('app.name') }));

    const user = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);

    const redirectTo = (navItem: AdminMenuItem) => {
        return navItem.url;
    }

    const onLogoutClick = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        dispatch(logout());
        navigate('/');
    }

    const renderItem = (navItem: AdminMenuItem, index: number, parentItem?: AdminMenuItem): React.ReactNode => {
        const isSelected = location.pathname.includes(navItem.url);

        return <div key={`menuItem_${index}`}>
            {(!parentItem || (parentItem && location.pathname.includes(parentItem.url))) &&
                <div className={styles.itemContainer}>
                    <div className={`${styles.menuDescription}`}>
                        <NavLink
                            to={redirectTo(navItem)}
                            className={`${styles.navLinks} ${isSelected && styles.active}`}
                        >
                            <span className={`${styles.description} ${navItem && isSelected && styles.coloredSelected}`}>{navItem.text}</span>
                        </NavLink>
                    </div>
                </div>
            }
        </div>
    }

    return (
        <div className={styles.container}>
            <NavLink to={'/admin'} className={`${styles.navLinks} ${styles.title}`}>
                {t('admin.title')}
            </NavLink>

            <div className={styles.menuContent}>
                <div className={styles.info}>
                    {menuItems.map((item, i) => (
                        renderItem(item, i)
                    ))}
                </div>
                <div
                    className={styles.info}
                    onClick={() =>
                        navigate(
                            `/admin/users/details/${user?.id}`
                        )
                    }
                >
                    {user?.mediaUrl
                        ? <img src={user.mediaUrl} className={styles.circularIcon} />
                        : <UserIcon
                            className={`${styles.circularIcon}`}

                        />}
                    <div className={styles.name}>{user?.realName}</div>
                    {/* <div className={styles.email}>{user?.email}</div> */}


                    <NavLink onClick={onLogoutClick} to={''} className={`${styles.navLinks}`}>
                        {t('menu.logout')}
                    </NavLink>
                </div>
            </div>
        </div>
    );
};

export default AdminMenuScreen;