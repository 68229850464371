import { FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styles from './InputError.module.scss';

interface Props {
    error: FieldError | undefined;
    maxLength?: number;
    minLength?: number;
}

function InputError ({ error, maxLength, minLength }: Props): (JSX.Element | null) {
    const { t } = useTranslation();
    const renderMessage = () => {

        console.log(error?.type);
        
        switch (error?.type) {
            case 'required':
                return t('common.messages.required_field');
            case 'used_email':
                return t('common.messages.email_already_in_use');
            case 'maxLength':
                if (maxLength) { return t('common.messages.max_length_detail', { maxLength }); } else { return t('common.messages.max_length_no_detail'); }
            case 'minLength':
                if (minLength) { return t('common.messages.min_length_detail', { minLength }); } else { return t('common.messages.min_length_no_detail'); }
            default:
                return error?.message;
        }
    };

    return error?.type
        ? (
            <div className={styles.message}>
                {renderMessage()}
            </div>
        )
        : null;
};

export default InputError;
