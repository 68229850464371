import React, { type MouseEventHandler } from 'react';
import colors from 'styles/export/colors.module.scss';
import styles from './ParaticalInformation.module.scss';
import RenderSVG from '../renderSVG/RenderSVG';
import Button from '../button/Button';
import MoneyFormat from '../moneyFormat/MoneyFormat';
import { useTranslation } from 'react-i18next';


export type TypologyInfo = {
    name?: string;
    value?: string;
    icon?: string;
}

type Props = {
    typologies?: TypologyInfo[],
    title?: string,
    price?: number,
    buttonLabel?: string,
    onClick?: () => void
};

const renderTypologies = (typologies: TypologyInfo[]) => {

    return typologies?.map((typology, ii) => (
        <div className={styles.typologyItem} key={ii}>
            {typology.icon && <RenderSVG item={typology.icon} className={styles.typologyIcon} />}
            <div className={styles.typologyValue}>{typology.value}</div>
        </div>
    ));
}

const ParaticalInformation = ({ typologies, title, price, buttonLabel, onClick, ...props }: Props) => {

    const { t } = useTranslation()
    
    return (
        <div className={styles.container}>
            <div className={styles.title}>{title}</div>
            <div className={styles.typologyContainer}>
                {renderTypologies(typologies ?? [])}
            </div>
            <div className={styles.price}>
                <span className={styles.priceValue}><MoneyFormat value={price} /></span>
                <span className={styles.priceLabel}>{t("our_houses.pratical_information.selling_price")}</span>
            </div>
            <div className={styles.buttonContainer}>
                <Button
                    key={'navigate-button'}
                    opaque={true}
                    color={colors.default}
                    textColor={colors.basicWhite}
                    onClick={onClick? onClick: () => { }}
                    className={styles.button}
                >
                    {buttonLabel}
                </Button>
            </div>
        </div>
    );
};

export default ParaticalInformation;
