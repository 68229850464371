/* eslint react/prop-types: 0 */
import React from 'react';
import { useSelector } from 'react-redux';
import { Route, RouteProps, Navigate } from 'react-router-dom';
import { Reducers } from '../../store/types';

type Props = {
    children?: any;
};

const ProtectedRoute = ({ children, ...props }: Props) => {
    const isAuthenticated = useSelector<Reducers, boolean>(state => state.authentication.isAuthenticated);
    if (!isAuthenticated) {
        return <Navigate to="/login" state={{ from: location }} />;
    }
    return children;
};
export default ProtectedRoute;
