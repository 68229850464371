import { Col, Row } from 'react-flexbox-grid';
import styles from './OurHouseDescription.module.scss';
import DivHtml from 'common/components/divHtml/DivHtml';
import { ReactComponent as MarkerIcon } from 'assets/svgs/marker-icon.svg'
import { useTranslation } from 'react-i18next';

type Props = {
    title?: string,
    description?: string,
    location?: string,
    praticalInfo?: any
};

const renderLocation = (location?: string) => {
    return (
        <Row>
             <Col xl={12} className={styles.locationContainer}>
                <div>
                    <MarkerIcon className={styles.icon}/>
                </div>
                <DivHtml html={location ?? ''} />
             </Col>
        </Row>
    )
}

const OurHouseDescription = ({ title, location, description, praticalInfo }: Props) => {
    const { t } = useTranslation();
    return (
        <div className={`${styles.container}`}>
            <div className={styles.containerLeft}>
                {title && <DivHtml className={styles.title} html={title} summernoteContent />}
                {location && renderLocation(location)}
                {description && (
                    <>
                        <div className={styles.description}>{t('our_houses.houses_description.title')}</div>
                        <DivHtml html={description} summernoteContent />
                    </>
                )}
            </div>
            {praticalInfo && (
                <div className={styles.containerRight}>
                    {praticalInfo}
                </div>
            )}
        </div>
    );
};

export default OurHouseDescription;
