import useIsMobile from 'common/hooks/useIsMobile';
import styles from './HorizontalTimeline.module.scss';

export type TimelineItem = {
    name: string,
    complete: boolean;
}

type Props = {
    items: TimelineItem[]
} 

const HorizontalTimeline = (props: Props) => {
    const isMobile = useIsMobile();

    const items = props.items || [];
    const lastCompleteIndex = items.findLastIndex(x => x.complete);

    return (
        <ul className={styles.timeline}>
            {items.map((item, i) => {
                const width = ((1/props.items.length) * 100) + '%';
                const hideText = isMobile && lastCompleteIndex > -1 && lastCompleteIndex !== i;
                return (
                    <li
                        style={{
                            width,
                            // wordWrap: isMobile && !hideText ? 'normal' : 'unset',
                        }}
                        key={`timeline_${i}`}
                        className={item.complete ? styles.complete : ''}
                    >
                        {hideText ? '' : item.name}
                    </li>
                );
            })}
        </ul>
    );
};

export default HorizontalTimeline;