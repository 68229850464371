import React from 'react';

type Props = {
    item: string;
    className?: string;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const RenderSVG: React.FC<Props> = ({ item, className }: Props) => {
    return (<div dangerouslySetInnerHTML={{ __html: item }} className={`${className || ''}`}/>);
}

export default RenderSVG;
