import { Paged } from 'api/common/types/Page';
import HousesService from 'api/houses/HousesService';
import { HouseDto } from 'api/houses/models/HouseDto';
import { HouseSearchCriteria } from 'api/houses/models/HouseSearchCriteria';
import Button from 'common/components/button/Button';
import Dropdown from 'common/components/dropdown/Dropdown';
import DropdownItem from 'common/components/popover/PopoverItem';
import InputSearch from 'common/components/inputs/inputSearch/InputSearch';
import ListingTable, { ListingTableColumn } from 'common/components/listingTable/ListingTable';
import PaginationWithInfo from 'common/components/pagination/PaginationWithInfo';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import Loading from 'common/services/Loading';
import Logger from 'common/services/Logger';
import { DEFAULT_PAGINATION_ITEMS_PER_PAGE, LOGGER_LOG_TYPE } from 'Config';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-flexbox-grid';
import { useTranslation } from 'react-i18next';
import { FaEllipsisH } from 'react-icons/fa';
import { useToasts } from 'react-toast-notifications';
import { useDebouncedCallback } from 'use-debounce';
import styles from './HousesListPage.module.scss';
import colors from 'styles/export/colors.module.scss';
import { useNavigate } from 'react-router-dom';
import NoImage from 'assets/imgs/empty_img.png';
import DivHtml from 'common/components/divHtml/DivHtml';

type Props = {
};

const HousesListPage: React.FC<Props> = ({ }: Props) => {
    const { t } = useTranslation();
    const { addToast } = useToasts();

    const [housesPage, setHousesPage] = useState<Paged<HouseDto>>();
    const [criteria, setCriteria] = useState<HouseSearchCriteria>({
        itemsPerPage: DEFAULT_PAGINATION_ITEMS_PER_PAGE,
        page: 1,
        orderBy: 'asc',
        orderColumn: 'h.title'
    });

    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
    const [itemToRemove, setItemToRemove] = useState<HouseDto | null>(null);
    const [pageLoad, setPageLoad] = useState(false);
    const navigate = useNavigate();

    const showRemoveItemDialog = async (item: HouseDto) => {
        setItemToRemove(item);
        setShowRemoveModal(true);
    }

    const onCancelRemove = () => {
        setItemToRemove(null);
        setShowRemoveModal(false);
    };

    const onRemove = async () => {
        if (itemToRemove === null) {
            addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
            return;
        }

        try {
            await HousesService.remove(itemToRemove);
            setCriteria({ ...criteria, page: 1 });
            onCancelRemove();
            addToast(t('common.messages.record_delete_success'), {
                appearance: 'success',
            });
        }
        catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't delete house`, error);
            addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
        }
    };

    const renderTableActionCell = (row: HouseDto) => {
        return (
            <Dropdown
                options={
                    <>
                        <DropdownItem url={`/admin/houses/details/${row.id}`}>
                            {t('common.details') ?? ''}
                        </DropdownItem>
                        <DropdownItem url={`/admin/houses/edit/${row.id}`}>
                            {t('common.edit') ?? ''}
                        </DropdownItem>
                        <DropdownItem onClick={(e) => { e.preventDefault();
                                                                                e.stopPropagation();
                                                                                showRemoveItemDialog(row); }
                                                                                }>
                            {t('common.delete') ?? ''}
                        </DropdownItem>
                    </>
                }
            >
                <div>
                    <FaEllipsisH />
                </div>
            </Dropdown>
        );
    };

    const onTableFilter = (field: string, isFilterAsc: boolean) => {
        setCriteria({ ...criteria, page: 1, orderBy: (isFilterAsc ? 'asc' : 'desc'), orderColumn: field });
    }

    const tableColumns: ListingTableColumn<HouseDto>[] = [
        {
            name: '',
            renderCell: (row) => <div className={styles.imageColumn}>{
                <div>
                    <img src={row.mediaUrl ?? NoImage} width={80}></img>
                </div>
            }
            </div>,
            width: '5%',
            hideOn: ['sm', 'md']
        },
        {
            name: t('houses.title') ?? '',
            field: 'title',
            onSearch: onTableFilter,
            searchField: 'h.title',
            renderCell: (row) => <DivHtml className={styles.text} html={row.title} />
        },
        {
            name: t('houses.location') ?? '',
            field: 'location',
            onSearch: onTableFilter,
            searchField: 'h.location'
        },
        {
            name: t('houses.price') ?? '',
            field: 'price',
            onSearch: onTableFilter,
            searchField: 'h.price',
            cellFormat: 'money',
            hideOn: ['sm', 'md']
        },
        {
            name: t('houses.finality') ?? '',
            field: 'finalityType',
            renderCell: (row) => <span>{t('houses.finaly_type.' + row.finalityType as any)}</span>,
            onSearch: onTableFilter,
            searchField: 'h.finality_type',
            hideOn: ['sm', 'md']
        },
        {
            name: '',
            width: '60px',
            cellAlignment: 'right',
            preventClick: true,
            renderCell: renderTableActionCell,
            cellStyle: { overflow: 'unset' }
        },
    ];

    const getData = async () => {
        try {
            Loading.show();
            const page = await HousesService.getList(criteria);
            setHousesPage(page);
            setPageLoad(true);
            Loading.hide();
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't get houses list`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }
    };

    const debounced = useDebouncedCallback((value: string) => {        
        setCriteria({ ...criteria, page: 1, allIn: value });
    }, 500);

    const navigateTo = (typeUrl?: string, id?: string) => {
        navigate(`/admin${typeUrl ? `/houses/${typeUrl}${id ? `/${id}` : ''}` : ''}`);
    }

    const onRowClick = (event: any, id?: string) => {
        if (id) {
            event.preventDefault();
            event.stopPropagation();
            if (event.ctrlKey) {
                window.open('/admin/houses/details/' + id, '_blank');
                return;
            }
            navigateTo('details', id);
        }
    }

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        if (pageLoad) { getData() }
    }, [criteria]);

    return (
        <div className={`${styles.container}`}>
            <div className={styles.header}>
                <div>
                    <InputSearch onChangeValue={debounced} placeholder={t('common.search')} ></InputSearch>
                </div>
            </div>
            <ListingTable
                columns={tableColumns}
                rows={housesPage?.items || []}
                onRowClick={(row, _, event) => onRowClick(event, row.id)}
                onHref={row => `/admin/houses/details/${row.id}`}
                allowHover={true}
                initialSearch={{ colField: 'h.title', isOrderAsc: true }}
            />
            <PaginationWithInfo
                itemName={t('houses.houses')}
                currentPage={housesPage?.currentPage || 1}
                pageItems={housesPage?.items.length || 0}
                totalItems={housesPage?.totalItems || 0}
                onChange={page => setCriteria({ ...criteria, page })}
            />

            <Row>
                <Col xs={12} className={styles.buttonsContainer}>
                    <Button
                        type='button'
                        key={'add-button'}
                        opaque={true}
                        color={colors.primary}
                        textColor={colors.basicWhite}
                        onClick={() => navigateTo('create', undefined)}
                    >
                        {t('common.add')}
                    </Button>
                </Col>
            </Row>
            <QuestionYesNo onNo={onCancelRemove} onYes={onRemove} isVisible={showRemoveModal} message={t('common.messages.remove_record_with_ident', { name: itemToRemove?.title ?? '' })} />
        </div>
    );
};

export default HousesListPage;