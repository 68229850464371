import React, { type MouseEventHandler } from 'react';
import colors from 'styles/export/colors.module.scss';
import styles from './Button.module.scss';

type Props = {
    type?: string,
    disabled?: boolean,
    color: string;
    textColor?: string,
    children: any;
    className?: string;
    opaque?: boolean;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    isDisabled?: boolean;
} & React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

const Button = ({ color, textColor, children, type = 'button', className, opaque = false, onClick, disabled, ...props }: Props) => {
    return (
        <button
            {...props}
            type={type}
            className={`${styles.button} ${className ?? ''}`}
            style={{
                backgroundColor: opaque ? color : colors.transparent,
                color: opaque ? textColor : (textColor ?? colors.basicWhite),
                border: opaque ? `1px solid ${colors.transparent}` : `1px solid ${color}`,
            }}
            onClick={onClick}
        >
            {children}
        </button>
    );
};

export default Button;
