import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { initAuthentication } from './store/authentication/action';
import { useDispatch } from 'react-redux';

interface Props {
    children: React.ReactElement
}

const Setup: React.FC<Props> = ({ children }: Props) => {
    const { i18n } = useTranslation();
    const [i18nLoaded, setI18nLoaded] = useState(false);
    const [loaded, setLoaded] = useState(false);

    const dispatch = useDispatch();
    
    const init = async () => {
        dispatch(initAuthentication(() => setLoaded(true)));
    }

    useEffect(() => {
        init();    
    }, []);
    
    useEffect(() => {
        if (i18n.isInitialized) {
            setI18nLoaded(true);
        }
    }, [i18n.isInitialized]);

    return loaded && i18nLoaded ? children : null;
};

export default Setup;
