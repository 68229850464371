import styles from './OurHouseDetails.module.scss';
import DivHtml from 'common/components/divHtml/DivHtml';
import Button from 'common/components/button/Button';
import colors from 'styles/export/colors.module.scss';
import { useTranslation } from "react-i18next"
import { useNavigate } from 'react-router-dom';

type Props = {
    details?: string, 
};

const renderDetails = (details?: string) => {
    const { t } = useTranslation()
    const navigate = useNavigate();	
    
    return (
        <>
            {details &&
                <DivHtml html={details} className={styles.details} summernoteContent />
            }
            <div className={styles.buttonContainer}>
				<Button
                    type='button'
                    key={'contact-button'}
                    opaque={true}
                    color={colors.greyGreen}
                    textColor={colors.basicWhite}
                    className={styles.button}
                    onClick={() => { navigate(t('our_houses.houses_details.contact_button_url') ?? '/contact') }}
                >
                    {t('our_houses.houses_details.contact_button').toUpperCase()}
                </Button>
			</div>
        </>);
    
}

const OurHouseDetails = ({ details, ...props }: Props) => {
    return (
        <div className={`${styles.container}`}>
            {renderDetails(details)}
        </div>
    );
};

export default OurHouseDetails;
