import { Route, Routes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SplittedLayout from 'common/components/splittedLayout/SplittedLayout';
import AdminMenuScreen, { AdminMenuItem } from './adminMenu/AdminMenuScreen';
import Footer from 'common/components/footer/Footer';
import ScrollToTop from 'common/components/scrollToTop/ScrollToTop';
import Menu from 'common/components/menu/Menu';
import UsersListPage from './partial-screens/users/UsersListPage';
import PolicyRoute from 'common/routes/PolicyRoute';
import UserPage from './partial-screens/users/UserPage';
import HousesListPage from './partial-screens/houses/HousesPage/HousesListPage';
import HousePage from './partial-screens/houses/HousePage/HousePage';

const AdminRouter = () => {
    const { t } = useTranslation();
    const menuItems: AdminMenuItem[] = [
        { text: t('admin.menu.users'), url: '/admin/users' },
        { text: t('admin.menu.houses'), url: '/admin/houses' },
    ];

    return (
        <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <ScrollToTop />
            <Menu isSpecialScreen={true}/> 
            <SplittedLayout leftSide={<AdminMenuScreen menuItems={menuItems} />}>
                <Routes>
                    <Route path="/users" element={<PolicyRoute type='OR' policies={['SETTINGUP_USERS_READ', 'SETTINGUP_USERS_WRITE']} ><UsersListPage /></PolicyRoute>} />
                    <Route path="/users/:type/:id?" element={<PolicyRoute type='OR' policies={['SETTINGUP_USERS_READ', 'SETTINGUP_USERS_WRITE']} ><UserPage /></PolicyRoute>} />                    
                    <Route path="/houses" element={<HousesListPage />} />
                    <Route path="/houses/:type" element={<HousePage />} />
                    <Route path="/houses/:type/:id?" element={<HousePage />} />
                </Routes>
            </SplittedLayout>
            <Footer />
        </div>
    );
}

export default AdminRouter;
