import React, { useState } from 'react';
import { UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styles from './Input.module.scss';

type Props = {
    type?: string    
    disabled?: boolean    
    showRequired?: boolean    
} & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

const Input = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
    const { type = 'text', disabled = false, showRequired = false, ...rest } = props;
    const { t } = useTranslation();    
   
    return (
        <>
            <input
                {...rest}
                ref={ref}
                type={type}                
                disabled={disabled}                
                onKeyDown={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                className={`${styles.input} ${props.disabled ? styles.disabled : ''} ${showRequired ? styles.warning : styles.noWarning} ${rest.className ? rest.className : ''}`}
            />           
        </>
    );
});

export default Input;
