import { type Action, type AuthenticationState, SET_AUTHENTICATION, SET_PROFILE, UPDATE_REFRESH_TOKEN } from './type';

const initialState: AuthenticationState = {
	isAuthenticated: false,
	profile: null,
	token: null,
	refreshToken: 1,
};

function rootReducer(state: AuthenticationState = initialState, action: Action): AuthenticationState {
	switch (action.type) {
		case SET_AUTHENTICATION:
			return {
				...state,
				isAuthenticated: action.profile ? true : false,
				profile: action.profile || null,
				token: action.token || null,
			};
		case SET_PROFILE:
			return {
				...state,
				profile: action.profile || null,
			};
		case UPDATE_REFRESH_TOKEN:
			return {
				...state,
				refreshToken: state.refreshToken + 1,
			};
		default:
			return state;
	}
}

export default rootReducer;
