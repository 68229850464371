import { Col, Row } from 'react-flexbox-grid';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { UserDto } from 'api/users/models/UserDto';
import UsersService from 'api/users/UsersService';
import Loading from 'common/services/Loading';
import Logger from 'common/services/Logger';
import { LOGGER_LOG_TYPE, HTTP_ERRORS } from 'Config';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import Button from 'common/components/button/Button';
import FormItem from 'common/components/formItem/FormItem';
import Input from 'common/components/inputs/input/Input';
import Label from 'common/components/label/Label';
import styles from './UserPage.module.scss';
import RolesService from 'api/roles/RolesService';
import { SelectValueLabel } from 'common/types/SelectValueLabel';
import InputError from 'common/components/inputs/inputError/InputError';
import { useSelector, useDispatch } from 'react-redux';
import { UserProfile } from 'api/account/models/UserProfile';
import { Reducers } from 'store/types';
import { initAuthentication } from 'store/authentication/action';
import SelectInput from 'common/components/inputs/SelectInput';
import colors from 'styles/export/colors.module.scss';
import hasPolicies from 'common/utils/hasPolicies';
import { useToasts } from 'react-toast-notifications';
import ImageInput from 'common/components/inputs/imageInput/ImageInput';

enum Types {
    create = 'create',
    edit = 'edit',
    details = 'details',
};

function UserPage(): JSX.Element {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { addToast } = useToasts();
    
    const { id, type } = useParams<{ id?: string; type: Types }>();
    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasUsersWritePolicy = hasPolicies(userProfile, ['SETTINGUP_USERS_WRITE']) || id === userProfile?.id;

    const { register, handleSubmit, setValue, control, formState, setError } = useForm<UserDto>();
    const errors = formState.errors;
    const [userToRemove, setUserToRemove] = useState<UserDto>({
        id: '',
        realName: '',
        email: ''
    });

    const [roles, setRoles] = useState<SelectValueLabel[]>([]);
    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
    const [isDetails, setIsDetails] = useState<boolean>(
        !!(type && type === Types.details)
    );
    const [avatarFile, setAvatarFile] = useState<File | null>(null);
    const [avatarUrl, setAvatarUrl] = useState<string | null>(null);

    useEffect(() => {
        setIsDetails(!!(type && type === Types.details));
        void getData();
        void getRoles();
    }, [id, type]);

    const getData = async () => {
        try {
            if (id && id != null) {
                Loading.show();
                const user = await UsersService.getById(id);
                setValues(user);

                console.log(user);
            }
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t  get user', error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });            
            Loading.hide();
        }
    };

    const getRoles = async () => {
        const data = await RolesService.getAll();
        const treatedRoles: SelectValueLabel[] = data.map((role) => ({
            value: role.id,
            label: role.name,
        }));
        setRoles(treatedRoles);
    };

    const setValues = (user: UserDto) => {
        setUserToRemove(user);
        setValue('id', user.id);
        setValue('roleId', user.roleId);
        setValue('realName', user.realName);
        setValue('email', user.email);
        setAvatarUrl(user.mediaUrl ?? null)
        Loading.hide();
    };

    const onSubmit = async (user: UserDto) => {
        try {
            Loading.show();

            if (id && id != null) {
                await UsersService.update(user, avatarFile);                
                addToast(t('common.messages.record_save_success'), { appearance: 'success' });
                if (id === userProfile?.id) { dispatch(initAuthentication(() => true) as any); }
                navigateTo();
            } else {
                await UsersService.create(user, avatarFile);
                addToast(t('common.messages.record_save_success'), { appearance: 'success' });                
                navigateTo();
            }
        } catch (error: any) {
            if (error.response.status === HTTP_ERRORS.CONFLICT) {
                addToast(t('common.messages.email_already_in_use'), { appearance: 'warning' });                
                Loading.hide();
                setError('email', { type: 'used_email' }, { shouldFocus: true });
            } else {
                Logger.error(LOGGER_LOG_TYPE.REQUEST, "Couldn't get users list", error);                
                addToast(t('common.messages.record_save_error'), { appearance: 'error' });
                Loading.hide();
            }
        }
    };

    const onError = async () => {
        addToast(t('common.messages.required_fields_empty'), { appearance: 'warning' });        
    };

    const onChooseAvatar = (file: File, dataUrl: string) => {
        setAvatarFile(file);
        setAvatarUrl(dataUrl);
    };

    const onDeleteAvatar = () => {
        setAvatarFile(null);
        setAvatarUrl(null);
        setValue('removeMedia', true);
    };


    const showRemoveItemDialog = async () => {
        setShowRemoveModal(true);
    };

    const onCancelRemove = () => {
        setShowRemoveModal(false);
    };

    const removeItem = async () => {
        if (id && id != null) {
            try {
                Loading.show();
                await UsersService.remove(userToRemove);
                addToast(t('common.messages.record_delete_success'), { appearance: 'success' });                
                Loading.hide();
                navigateTo();
            } catch (error) {
                setShowRemoveModal(false);
                addToast(t('common.messages.record_delete_error'), { appearance: 'error' });                
                Loading.hide();
            }
        }
    };

    const navigateTo = (typeUrl?: string, idToGo?: string) => {
        if (typeUrl) {
            navigate(`/admin/users/${typeUrl}/${idToGo}`);
            setIsDetails(typeUrl === Types.details);
        } else {
            navigate(`/admin/users`);
        }
    };

 

    return (
        <div className={styles.container}>
            
            <form onSubmit={handleSubmit(onSubmit, onError)}>                
                <Row>
                    <Col xs={12} md={9}>
                        <FormItem>
                            <Label required={!!errors.realName}>{t('users.form.name') + '*'}</Label>
                            <Input
                                type="text"
                                {...register('realName', { required: true })}
                                name="realName"
                                disabled={isDetails}                                
                                showRequired={!!errors.realName}
                            />
                            <InputError error={errors.realName} maxLength={250} />
                        </FormItem>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} md={9}>
                        <FormItem>
                            <Label required={!!errors.realName}>{t('users.form.email') + '*'}</Label>
                            <Input
                                type="text"
                                {...register('email', { required: true })}
                                name="email"
                                disabled={isDetails}                                
                                showRequired={!!errors.email}
                            />
                            <InputError error={errors.email} maxLength={100} />
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={9}>
                        <FormItem>
                            <Label required={!!errors.roleId}>{t('users.form.role') + '*'}</Label>
                            <SelectInput
                                optionsList={roles}
                                disabled={isDetails}
                                placeholder={t('users.form.role_placeholder') ?? ''}
                                required={true}
                                name="roleId"
                                control={control}
                                showRequired={!!errors.roleId}
                            />
                            <InputError error={errors.roleId} />
                        </FormItem>
                    </Col>
                </Row>

                <Row>
                <Col xl={3} lg={4} md={4} sm={12} xs={12}>
                            <div className={styles.photoDiv}>
                                <ImageInput
                                    onChooseFile={onChooseAvatar}
                                    previewUrl={avatarUrl}
                                    onDelete={onDeleteAvatar}
                                    disabled={isDetails}
                                    forceAspectRatio={true}
                                />
                            </div>
                        </Col>
                        </Row>



                <Row>
                    <Col xs={12} md={9} className={styles.buttonsContainer}>
                    {type !== Types.details && hasUsersWritePolicy && <Button
                            type='submit'
                            key={'add-or-update-button'}
                            opaque={true}
                            color={colors.primary}
                            textColor={colors.basicWhite}
                            onClick={() => { }}
                        >
                            {type === Types.create ? t('common.add') : t('common.save')}
                        </Button>}
                        {type === Types.details && hasUsersWritePolicy && <Button
                            type='button'
                            key={'update-button'}
                            opaque={true}
                            color={colors.primary}
                            textColor={colors.basicWhite}
                            onClick={() => navigateTo('edit', userToRemove.id)}
                        >
                            {t('common.edit')}
                        </Button>}
                        <Button
                            type='button'
                            key={'cancel-button'}
                            opaque={true}
                            color={colors.default}
                            textColor={colors.basicWhite}
                            className={styles.paddingLeft}
                            onClick={() => navigateTo()}
                        >
                            {t('common.cancel')}
                        </Button>
                    </Col>
                </Row>
            </form>
            <QuestionYesNo
                onNo={onCancelRemove}
                onYes={removeItem}
                isVisible={showRemoveModal}
                message={t('common.messages.remove_record_with_ident', {
                    name: userToRemove?.realName ?? '',
                })}
            />
        </div>
    );
};

export default UserPage;
