import React from 'react';
import type { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import styles from './NavbarItem.module.scss';

interface Props {
    url?: string;    
    children: ReactNode | ReactNode[];
    className?: string;
    colorMode: 'dark' | 'light';
};

const NavbarItem = ({ url, children, className, colorMode }: Props) => (
    <NavLink
        to={url ?? '#'}
        className={`${styles.link} ${className ?? ''}`}      
        style={({ isActive }) => ({ fontWeight: isActive ? 'bold' : '' })}>
        <div className={`${styles.title} ${colorMode === 'dark' ? styles.dark : styles.light}`}>
            {children}
        </div>
    </NavLink>
);

export default NavbarItem;
