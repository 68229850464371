import { useTranslation } from 'react-i18next';

interface Props {
    logoMode: 'dark' | 'light' | 'transparent' | 'transparent_gray';
}

const Logo = ({ logoMode }: Props) => {
    const { t } = useTranslation();

    const loadLogo = () => {
        switch (logoMode) {
            case 'light':
                return 'logo.white_url'
            case 'dark':
                return 'logo.gray_url'
            case 'transparent':
                return 'logo.transparent_url'
            case 'transparent_gray':
                return 'logo.transparent_gray_url' 
        }
 
    }
    return (
        <a href="/">
            <img src={t(loadLogo(), { interpolation: { escapeValue: true } }) ?? '#'} />
        </a>
    );
};

export default Logo;
