import useTitle from 'common/hooks/useTitle';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './HomePage.module.scss';
import Header from 'common/components/header/Header';
import Button from 'common/components/button/Button';
import colors from 'styles/export/colors.module.scss';
import Footer from 'common/components/footer/Footer';
import ScrollToTop from 'common/components/scrollToTop/ScrollToTop';
import SendinBlue from 'common/components/sendinBlue/SendinBlue';
import Container from 'common/components/container/Container';
import ConceptListing from './conceptListing/ConceptListing';
import AdvantagesListing from './advantagesListing/AdvantagesListing';
import DivHtml from 'common/components/divHtml/DivHtml';
import { Link, useNavigate } from 'react-router-dom';
import useIsMobile from 'common/hooks/useIsMobile';
import HousesListing from './housesListing/HousesListing';
import Menu from 'common/components/menu/Menu';

const HomePage: React.FC = () => {
    const { t } = useTranslation();
    useTitle(t('home.page_title', { appName: t('app.name') }));
    const isMobile = useIsMobile();
    const navigate = useNavigate();
    
    return (
        <div className={styles.container}>
            <ScrollToTop />
            <Menu /> 
            <Header
                title={t('home.header.title', { interpolation: { escapeValue: false } })}
                subTitle={t('home.header.description', { interpolation: { escapeValue: false } })}
                backgroundUrl={t('home.header.background_url', { interpolation: { escapeValue: true } })}
                buttons={[
                    <Button
                        key={'main-button'}
                        color={colors.basicWhite}
                        textColor={colors.basicWhite}
                        onClick={() => { navigate(t('home.header.button_url') ?? '/contact') }}
                    >
                        {t('home.header.main_button').toUpperCase()}
                    </Button>
                ]}
            />

            <Container className={styles.bodyContainer}>
                <HousesListing />
                <ConceptListing />
                <AdvantagesListing />
            </Container>
           
            <div className={styles.homePartenersImg} style={{ backgroundImage: isMobile ? `url(${t('home.partners.background_image_mobile')})` : `url(${t('home.partners.background_image')})` }}>
            <Container>
                <div className={styles.homePartner}>
                    
                    <DivHtml className={styles.partnerText} html={t('home.partners.description', { interpolation: { escapeValue: false } }).toUpperCase()} />
                   
                        <Link to="/partners" className={styles.partnerButtonDiv}>
                            <Button
                                color={colors.basicWhite}
                                textColor={colors.basicWhite}
                                className={styles.partnerButton}
                                onClick={() => { }}
                                >
                                {t('home.partners.button_text').toUpperCase()}
                            </Button>
                        </Link> 
                       
                </div>
                </Container>           
            </div> 

            <Container className={styles.home_contact}>
                <DivHtml className={styles.contactText} html={t('home.contact_us.description', { interpolation: { escapeValue: false } }).toUpperCase()} />

                <Link to="/contact" className={styles.contactButton}>
                            <Button
                                color={colors.basicGrayDarker}
                                textColor={colors.basicGrayDarker}                               
                                onClick={() => {}}
                                >
                                {t('home.contact_us.button_text').toUpperCase()}
                            </Button>
                </Link> 
            </Container> 

            <SendinBlue />
            <Footer/>
        </div>
    );
};

export default HomePage;
