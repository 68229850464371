import React from 'react';
import { UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styles from './FormInput.module.scss';

type Props = {
    register?: UseFormRegister<any>;
    name?: string
    disabled?: boolean
    required?: boolean
    showRequired?: boolean
    maxLength?: number;
    rows?: number;
    placeholder?: string
} & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

const TextArea = (props: Props) => {
    const { register, name, disabled = false, required = false, showRequired = false, maxLength, rows, placeholder, ...rest } = props;
    const { t } = useTranslation();

    const registerProps: any = (register && name
        ? register(name, {
            required,
            maxLength,
            validate: required ? (value) => { return !value?.trim() ? (t('shared_translations.messages.required_field') ?? false) : true; } : undefined
        })
        : {});

    const checkChange = (e: any) => {
        if (registerProps?.onChange) {
            registerProps?.onChange(e);
        }
    };

    return (

        <div className={`${styles.box} ${showRequired ? styles.warning : styles.noWarning}`}>
            <textarea
                {...rest}
                {...registerProps}
                rows={rows}
                disabled={disabled}
                placeholder={placeholder}   
                onChange={(e) => checkChange(e)}
                className={`${styles.input} ${styles.textArea} ${rest.className ? rest.className : ''}`}
            />
        </div>
    );
};

export default TextArea;
