import styles from './Menu.module.scss';
import useIsMobile from 'common/hooks/useIsMobile';
import NavbarItem from '../navbarItem/NavbarItem';
import { t } from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import { ReactComponent as MenuIcon } from 'assets/svgs/mobile_menu_icon.svg'
import { useEffect, useState } from 'react';

import { changeSideBar } from 'store/sideBar/action';
import Logo from '../logo/Logo';

export const MENU_CLOSED_HEIGHT_SPACE = 0;
export const MENU_OPEN_HEIGHT_SPACE = 125;
export const MENU_OPEN_SCROLL_LIMIT = 60;

interface Props {
    isSpecialScreen?: boolean;
}

export interface NavbarItemInfo {
    id: string;
    text: string;
    url: string;
}

const Menu = ({ isSpecialScreen }: Props) => {
    const isMobile = useIsMobile();
    const dispatch = useDispatch();
    const [colorMode, setColorMode] = useState<'light' | 'dark'>(isSpecialScreen ? 'dark' : 'light');
    const [logoMode, setLogoMode] = useState<'dark' | 'light' | 'transparent' | 'transparent_gray'>(isSpecialScreen ? 'dark' : 'light');
    const [isScrolledDown, setIsScrolledDown] = useState<boolean>(false);
    const [whiteContainer, setWhiteContainer] = useState<boolean>(false);

    const isAuthenticated = useSelector<Reducers, boolean>(state => state.authentication.isAuthenticated);

    useEffect(() => {
        window.addEventListener('scroll', scrollDown);
        return () => { window.removeEventListener('scroll', scrollDown) }
    }, [])

    const scrollDown = () => {
        if (isSpecialScreen) { return }
        window.scrollY >= MENU_OPEN_SCROLL_LIMIT ? scrolledMenu() : topMenu();
    }
    
    const navbarItems: NavbarItemInfo[] = [
        { id: 'about', text: t('menu.about'), url: '/about' },
        { id: 'our_houses', text: t('menu.our_houses'), url: '/our_houses' },
        { id: 'partners', text: t('menu.partners'), url: '/partners' },
        { id: 'contact', text: t('menu.contact'), url: '/contact' }        
    ];

    const navbarAdminItems: NavbarItemInfo[] = [
        { id: 'backoffice', text: t('menu.backoffice'), url: '/admin' },        
    ];

    const itemsElements = navbarItems.map((item, i) => (
        <div key={i}>
            <NavbarItem
                url={item.url}             
                colorMode={colorMode}
            >
                {item.text}
            </NavbarItem>
        </div>
    ));
    
    const adminItemsElements = navbarAdminItems.map((item, i) => (
        <div key={10 + i}>
            <NavbarItem
                url={item.url}              
                colorMode={colorMode}
            >
                {item.text}
            </NavbarItem>
        </div>
    ));

    const openSideBar = () => {
        dispatch(changeSideBar());
    };

    const topMenu = () => {
        setColorMode('light')
        setLogoMode('light')
        setIsScrolledDown(false)
        
    }

    const scrolledMenu = () => {
        setColorMode('dark')
        setLogoMode('transparent_gray');
        setIsScrolledDown(true)
        setWhiteContainer(false);
    }

    const onMouserEnter = () => {
        if (!isScrolledDown && !isSpecialScreen && !isMobile) {
            setColorMode('dark');
            setLogoMode('dark');
            setWhiteContainer(true)
        }
    }

    const onMouserLeave = () => {
        if (!isScrolledDown && !isSpecialScreen && !isMobile) {
            setColorMode('light');
            setLogoMode('light');
            setWhiteContainer(false);
        }

    }
    

    return (
        <div className={`${isSpecialScreen ? '' : styles.container} ${(isScrolledDown) ? styles.scrolledDownContainer : ''} ${whiteContainer ? styles.whiteContainer : ''}`} 
            onMouseOver={() => { onMouserEnter() }} onMouseOut={() => { onMouserLeave() }}>
            <div className={`${styles.logoContainer} ${isScrolledDown ? styles.stickyContainer : ''}` }>
               {isMobile && <div className={`${styles.menuIcon} ${(isScrolledDown || isSpecialScreen) ? styles.blackIcon : ''}`}>
                    <MenuIcon onClick={() => { openSideBar() }}/>
                </div>}
                <Logo logoMode={logoMode} />
            </div>
            { !isMobile && <div className={styles.itemsContainer}>
                <>
                {itemsElements}
                { isAuthenticated && adminItemsElements}
                </>
            </div> }
        </div>
    );
};

export default Menu;
