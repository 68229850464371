import React from 'react';
import { useTranslation } from 'react-i18next';
import Container from '../container/Container';
import styles from './Footer.module.scss';
import DivHtml from '../divHtml/DivHtml';
import { Link } from 'react-router-dom';
import Logo from '../logo/Logo';

interface Social {
    url: string;
    icon_url: string;
}

const Footer: React.FC<any> = () => {
    const { t } = useTranslation();

    const socials: Social[] = t('footer.socials', { returnObjects: true }) ?? [];
    
    return (
        <div className={`${styles.container} ${styles.containerDark}`}>
            <Container>
                <div className={styles.information}>
                    <div className={`${styles.column} ${styles.logoContainer}`}>
                        <div>
                            <Logo logoMode="transparent" />
                          
                        </div>
                        <div className={styles.socialsContainer}>  
                            {socials.map((social, i) => (
                                <a key={i} href={social.url} target="_blank" rel="noreferrer">
                                    <img src={social.icon_url} />
                                </a>
                            ))}
                        </div>
                    </div>
                    <div className={styles.column}>
                        <div className={styles.label}>{t('footer.get_in_touch.label')}</div>
                        <DivHtml className={styles.text} html={t('footer.get_in_touch.text', { interpolation: { escapeValue: false } })} />
                    </div>
                    <div className={styles.column}>
                        <div className={styles.label}>{t('footer.office.label')}</div>
                        <div className={styles.text}><b>{t('footer.office.area')}</b></div>
                        <DivHtml className={styles.text} html={t('footer.office.address', { interpolation: { escapeValue: false } })} />
                    </div>
                    <div className={styles.column}>
                        <div className={styles.label}>{t('footer.legal.label')}</div>
                        <Link to="/about" className={styles.link}>{t('footer.legal.about')}</Link>
                        <Link to="/our_houses" className={styles.link}>{t('footer.legal.our_houses')}</Link>
                        <Link to="/privacy_policy" className={styles.link}>{t('footer.legal.privacy_policy')}</Link>
                    </div>
                </div>
                <div className={styles.bottom}>
                    <DivHtml className={styles.copyrightText} html={t('footer.copyright_text', { year: new Date().getFullYear(), interpolation: { escapeValue: false } })} />
                </div>
            </Container>
        </div>
    );
};

export default Footer;
