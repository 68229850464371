/* eslint react/prop-types: 0 */
import React from 'react';
import { useSelector } from 'react-redux';
import { Route, RouteProps, Navigate } from 'react-router-dom';
import { Reducers } from '../../store/types';
import { POLICIES } from 'Config';
import UsersService from 'api/users/UsersService';
import { UserProfile } from 'api/account/models/UserProfile';

type Props = {
    children?: any;
    policies: (keyof typeof POLICIES)[];
    type?: 'OR' | 'AND';
};

const PolicyRoute = ({ children, policies, type = 'AND', ...props }: Props) => {
    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const userPolicies = userProfile?.policies || [];
    const allowed = UsersService.hasPolicies(userPolicies, policies, type);   

    if (!allowed) {
        return <Navigate to="/" state={{ from: location }} />;
    }
    return children;
};
export default PolicyRoute;
