import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ListingTable, { ListingTableColumn } from 'common/components/listingTable/ListingTable';
import PaginationWithInfo from 'common/components/pagination/PaginationWithInfo';
import { DEFAULT_PAGINATION_ITEMS_PER_PAGE, LOGGER_LOG_TYPE } from 'Config';
import { useToasts } from 'react-toast-notifications';
import { UserDto } from 'api/users/models/UserDto';
import { UserSearchCriteria } from 'api/users/models/UserSearchCriteria';
import Loading from 'common/services/Loading';
import UsersService from 'api/users/UsersService';
import Logger from 'common/services/Logger';
import { Paged } from 'api/common/types/Page';
import styles from './UsersListPage.module.scss';
import { useDebouncedCallback } from 'use-debounce';
import DropdownItem from 'common/components/popover/PopoverItem';
import { FaEllipsisH } from 'react-icons/fa';
import Dropdown from 'common/components/dropdown/Dropdown';
import QuestionYesNo from 'common/components/questionYesNo/QuestionYesNo';
import { useNavigate } from 'react-router-dom';
import { UserProfile } from 'api/account/models/UserProfile';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';
import InputSearch from 'common/components/inputs/inputSearch/InputSearch';
import { Col, Row } from 'react-flexbox-grid';
import Button from 'common/components/button/Button';
import colors from 'styles/export/colors.module.scss';

type Props = {
};

const UsersListPage: React.FC<Props> = ({ }: Props) => {
    const { t } = useTranslation();
    const { addToast } = useToasts();

    const [usersPage, setUsersPage] = useState<Paged<UserDto>>();
    const [criteria, setCriteria] = useState<UserSearchCriteria>({
        itemsPerPage: DEFAULT_PAGINATION_ITEMS_PER_PAGE,
        page: 1,
        orderBy: 'asc',
        orderColumn: 'u.real_name'
    });

    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
    const [itemToRemove, setItemToRemove] = useState<UserDto | null>(null);
    const [pageLoad, setPageLoad] = useState(false);
    const navigate = useNavigate();

    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);
    const hasUsersWritePolicy = UsersService.hasPolicies(userProfile?.policies || [], ['SETTINGUP_USERS_WRITE']);

    const showRemoveItemDialog = async (item: UserDto) => {
        setItemToRemove(item);
        setShowRemoveModal(true);
    }

    const onCancelRemove = () => {
        setItemToRemove(null);
        setShowRemoveModal(false);
    };

    const onRemove = async () => {
        if (itemToRemove === null) {
            addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
            return;
        }

        try {
            await UsersService.remove(itemToRemove);
            setCriteria({ ...criteria, page: 1 });
            onCancelRemove();
            addToast(t('common.messages.record_delete_success'), {
                appearance: 'success',
            });
        }
        catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't delete user`, error);
            addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
        }
    };

    const renderTableActionCell = (row: UserDto) => {
        return (
            <Dropdown
                options={
                    <>
                        <DropdownItem url={`/admin/users/details/${row.id}`}>
                            {t('common.details') ?? ''}
                        </DropdownItem>
                        {hasUsersWritePolicy && <DropdownItem url={`/admin/users/edit/${row.id}`}>
                            {t('common.edit') ?? ''}
                        </DropdownItem>}
                        {hasUsersWritePolicy && <DropdownItem onClick={(e) => { e.preventDefault();
                                                                                e.stopPropagation();
                                                                                showRemoveItemDialog(row); }
                                                                                }>
                            {t('common.delete') ?? ''}
                        </DropdownItem>}
                    </>
                }
            >
                <div>
                    <FaEllipsisH />
                </div>
            </Dropdown>
        );
    };

    const onTableFilter = (field: string, isFilterAsc: boolean) => {
        setCriteria({ ...criteria, page: 1, orderBy: (isFilterAsc ? 'asc' : 'desc'), orderColumn: field });
    }

    const tableColumns: ListingTableColumn<UserDto>[] = [
        {
            name: t('users.list.name') ?? '',
            field: 'realName',
            onSearch: onTableFilter,
            searchField: 'u.real_name'
        },
        {
            field: 'email',
            name: t('users.list.email') ?? '',
            onSearch: onTableFilter,
            searchField: 'u.email',
            cellStyle: { wordBreak: 'break-all' }
        },
        {
            name: '',
            width: '60px',
            cellAlignment: 'right',
            preventClick: true,
            renderCell: renderTableActionCell,
            cellStyle: { overflow: 'unset' }
        },
    ];

    const getData = async () => {
        try {
            Loading.show();
            const page = await UsersService.getList(criteria);
            setUsersPage(page);
            setPageLoad(true);
            Loading.hide();
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, `Couldn't get users list`, error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }
    };

    const debounced = useDebouncedCallback((value: string) => {        
        setCriteria({ ...criteria, page: 1, allIn: value });
    }, 500);


    const navigateTo = (typeUrl?: string, id?: string) => {
        navigate(`/admin${typeUrl ? `/users/${typeUrl}${id ? `/${id}` : ''}` : ''}`);
    }

    const onRowClick = (event: any, id?: string) => {
        if (id) {
            event.preventDefault();
            event.stopPropagation();
            if (event.ctrlKey) {
                window.open('/admin/users/details/' + id, '_blank');
                return;
            }
            navigateTo('details', id);
        }
    }
    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        if (pageLoad) { getData() }
    }, [criteria]);


    return (
        <div className={`${styles.container}`}>
            <div className={styles.header}>
                <div>
                    <InputSearch onChangeValue={debounced} placeholder={t('common.search')} ></InputSearch>
                </div>
            </div>
            <ListingTable
                columns={tableColumns}
                rows={usersPage?.items || []}
                onRowClick={(row, _, event) => onRowClick(event, row.id)}
                onHref={row => `/admin/users/details/${row.id}`}
                allowHover={true}
                initialSearch={{ colField: 'u.real_name', isOrderAsc: true }}
            />
            <PaginationWithInfo
                itemName={t('users.list.users')}
                currentPage={usersPage?.currentPage || 1}
                pageItems={usersPage?.items.length || 0}
                totalItems={usersPage?.totalItems || 0}
                onChange={page => setCriteria({ ...criteria, page })}
            />

            <Row>
                <Col xs={12} className={styles.buttonsContainer}>
                    <Button
                        type='button'
                        key={'add-button'}
                        opaque={true}
                        color={colors.primary}
                        textColor={colors.basicWhite}
                        onClick={() => navigateTo('create', undefined)}
                    >
                        {t('common.add')}
                    </Button>
                </Col>
            </Row>
            <QuestionYesNo onNo={onCancelRemove} onYes={onRemove} isVisible={showRemoveModal} message={t('common.messages.remove_record_with_ident', { name: itemToRemove?.realName ?? '' })} />
        </div>
    );
};

export default UsersListPage;
