import styles from './OurHouseDetailsHeader.module.scss';
import Menu from 'common/components/menu/Menu';

const OurHouseDetailsHeader = () => {
    return (
        <div className={`${styles.container}`}>
            <Menu isSpecialScreen={true}/>
        </div>
    );
};

export default OurHouseDetailsHeader;
