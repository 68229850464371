import AuthenticatedLayout from 'common/layouts/authenticatedLayout/AuthenticatedLayout';
import ProtectedRoute from 'common/routes/ProtectedRoute';
import AboutPage from 'pages/about/AboutPage';
import AdminRouter from 'pages/admin/AdminRouter';
import ContactsPage from 'pages/contacts/ContactsPage';
import NotFoundPage from 'pages/errors/NotFoundPage';
import HomePage from 'pages/home/HomePage';
import LoginPage from 'pages/login/LoginPage';
import OurHouseDetailsPage from 'pages/ourHouseDetails/OurHouseDetailsPage';
import OurHousesPage from 'pages/ourHouses/OurHousesPage';
import PartnersPage from 'pages/partners/PartnersPage';
import PolicyPage from 'pages/policy/PolicyPage';
import { Navigate, Route, Routes, BrowserRouter } from 'react-router-dom';

const Router = () => {
    return (
        <BrowserRouter>
        <AuthenticatedLayout>
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/login/*" element={<LoginPage />} />
                <Route path="/about" element={<AboutPage />} />
                <Route path="/our_houses" element={<OurHousesPage />} />
                <Route path="/our_houses/details/:id" element={<OurHouseDetailsPage />} />
                <Route path="/partners" element={<PartnersPage />} />
                <Route path="/contact" element={<ContactsPage />} />
                <Route path="/privacy_policy" element={<PolicyPage />} />                
                <Route path="/admin/*" element={<ProtectedRoute><AdminRouter /></ProtectedRoute>} />
                <Route path="/404" element={<NotFoundPage />} />
                <Route path="*" element={<Navigate replace={true} to="/404" />} />
            </Routes>
         </AuthenticatedLayout> 
        </BrowserRouter>
    );
};

export default Router;
