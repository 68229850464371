import Http from '../../common/services/Http';
import { ContactForm, MailForm } from './models/Contact';

class UserService {
   
    public registerContact(model: ContactForm) {
        return Http.post('mail/send-mail', model);
    }

    public addMail(model: MailForm) {
        return Http.post('mail/add-mail', model);
    }

}

export default new UserService();