import styles from './HouseCover.module.scss';
import NoImage from 'assets/imgs/empty_img.png';
import useIsMobile from 'common/hooks/useIsMobile';
import { CSSProperties } from 'react';
import { Tooltip } from 'react-tooltip'
import { ReactComponent as BackIcon } from 'assets/svgs/back.svg';
import { useNavigate } from 'react-router-dom';

interface Props {
	style?: CSSProperties;
	imageCoverUrl?: string;
    icon3D?: any;
    link3D?: string;
	tooltipHtml3D?: JSX.Element;
    imageGalleryUrl?: string;
    textImageGallery?: string;
    onClickImageGallery?: () => void;
}

const HouseCover = (props: Props) => {
	const isMobile = useIsMobile();
	const navigate = useNavigate();	

	const onClickGoBack = () => {
		navigate('/our_houses');
	}

    return (
		<div className={styles.container} style={props.style}>
			<div className={styles.imageCoverUrl} style={{ backgroundImage: `url(${props.imageCoverUrl ?? NoImage})` }}></div>

			{isMobile && (
				<div className={styles.mobileHeader}>
					<div className={styles.mobileHeaderCol}>
						<div className={styles.mobileHeaderIcon} onClick={onClickGoBack}>
							<BackIcon />
						</div>
					</div>
					{props.icon3D && props.link3D && (
						<div className={styles.mobileHeaderIcon} onClick={() => window.open(props.link3D, "_blank")}>
							{props.icon3D}
						</div>
					)}
				</div>
			)}

			{!isMobile && props.icon3D && props.link3D && (
				<div className={styles.area3d} >
					<div data-tooltip-id='link3d' className={styles.button3d} onClick={() => window.open(props.link3D, "_blank")}>
						{props.icon3D}
					</div>
				</div>
			)}

			{!isMobile && props.icon3D && props.link3D && (
				<Tooltip id='link3d'
					style={{ backgroundColor: 'white', color: 'black', borderRadius: '1rem', paddingRight: 25, paddingLeft: 25, marginTop: 20 }}
					place="left"
				>
					{props.tooltipHtml3D}
				</Tooltip>
			)}

			{props.style && props.imageGalleryUrl && props.textImageGallery && props.onClickImageGallery && (
				<div className={styles.areaImageGallery}>
					<div className={styles.imageGallery} style={{ backgroundImage: `url(${props.imageGalleryUrl})` }} onClick={props.onClickImageGallery}></div>
					<div className={styles.textGallery} onClick={props.onClickImageGallery}>
						{props.textImageGallery && <span>{props.textImageGallery}</span>}
					</div>
				</div>
			)}
		</div>
	);
};

export default HouseCover;