
import React from 'react';
import styles from './SideSwappingList.module.scss';
import Button from 'common/components/button/Button';
import colors from 'styles/export/colors.module.scss';
import Container from 'common/components/container/Container';
import DivHtml from 'common/components/divHtml/DivHtml';
import useIsMobile from 'common/hooks/useIsMobile';

interface Item {
    title: string;
    description: string;
    image: string;
    button_text: string | null;
    button_url: string | null;
}

interface Props {
    items: Item[];
    itemIdPrefix?: string;
}

const SideSwappingList: React.FC<any> = ({ items, itemIdPrefix }: Props) => {
    const isMobile = useIsMobile();

    const isOddorEven = (key: number) => {
        
        if (isMobile) {
            return false;
        } else {
            if (key % 2 == 0) {
                return true
            }

            return false
        }
    }

    return (
        <Container style={{ marginTop: '5rem' }}>
            {items.map((item, key) => (
                <div key={key} className={styles.itemContainer} id={itemIdPrefix ? `${itemIdPrefix}${key}` : undefined}>
                    { isOddorEven(key) && <img
                        className={styles.webImage}
                        src={item.image}
                    />}
                    <div className={ ` ${styles.itemText} ${isOddorEven(key) ? styles.itemEven : styles.itemOdd}` } >
                        <div className={styles.titleContainer}>
                            <div className={styles.itemTitle}>{item.title.toUpperCase()}</div>
                            <hr className={styles.itemHR}/>
                        </div>
                       
                        <DivHtml className={styles.itemDescription} html={item.description} />

                        { item.button_text !== null && <Button
                            color={colors.basicGrayDarker}
                            textColor={colors.basicGrayDarker}
                            className={styles.itemButton}
                            onClick={() => {
                                if (item.button_url) {
                                window.open(item.button_url);
                            } }}
                        >
                            {item.button_text.toUpperCase()}
                        </Button>}
                    </div>   
                    { !isOddorEven(key) && <img
                        className={styles.webImage}
                        src={item.image}
                    />}                    
                </div>
            ))}
        </Container>
    )
}

export default SideSwappingList;