import React from 'react';

const getTextContentOnly = (html: string) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    const walker = document.createTreeWalker(
        doc.body, 
        NodeFilter.SHOW_TEXT, 
        null, 
    );
    const texts = [];

    let node;
    // eslint-disable-next-line no-cond-assign
    while (node = walker.nextNode()) {
        texts.push(node.nodeValue);
    }
    return texts;
}

type Props = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    html: string;
    className?: string;
    summernoteContent?: boolean;
    onlyText?: boolean;
};

const DivHtml = ({ html, summernoteContent, onlyText, ...props }: Props) => {
    let htmlContent = html;

    if (onlyText) {
        const parsedStrs = getTextContentOnly(html);
        if (parsedStrs && parsedStrs.length > 0) {
            htmlContent = parsedStrs.join(' ').replace(/\s+/g, ' ').trim();
        } else {
            htmlContent = '';
        }
    }

    return (
        <div
            {...props}
            dangerouslySetInnerHTML={{ __html: htmlContent }}
            className={`${summernoteContent ? 'summernoteContent' : ''} ${props.className ? props.className : ''}`}
        />
    );
};

export default DivHtml;
