import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../HomePage.module.scss';
import stylesCL from './ConceptListing.module.scss';
import DivHtml from 'common/components/divHtml/DivHtml';
import useIsMobile from 'common/hooks/useIsMobile';
import Button from 'common/components/button/Button';
import colors from 'styles/export/colors.module.scss';
import { Link } from 'react-router-dom';

interface Items {
    description: string;
    image: string;
}

interface Concept {
    title: string;
    button_text: string;
}

const ConceptListing: React.FC = () => {
    const { t } = useTranslation();
    const isMobile = useIsMobile();

    const concept: Concept = t('home.concept', { returnObjects: true });
    const conceptList: Items[] = t('home.concept.items', { returnObjects: true }) ?? [];

    const isOddorEven = (key: number) => {
            
        if (isMobile) {
            return true;
        } else {
            if (key % 2 == 0) {
                return true
            }

            return false
        }
        
    }

    return (
        <div className={stylesCL.conceptContainer}>
            <div className={styles.sectionTitle}>{concept.title.toUpperCase()}</div>
            <div className={stylesCL.conceptGridLayout}>
            {
                conceptList.map((item, key) => (
                    <div key={key} >
                        {isOddorEven(key) && <DivHtml className={`${stylesCL.conceptDescription}`} html={item.description}/>}
                        <img src={item.image} className={stylesCL.conceptImage}/>
                        {!isOddorEven(key) && <DivHtml className={`${stylesCL.conceptDescription}`} html={item.description}/>}
                    </div>
                ))
            }
            </div>

            <div className={stylesCL.conceptButtonContainer}> 
                <Link to="/about">
                    <Button
                        color={colors.basicGrayDarker}
                        textColor={colors.basicGrayDarker}
                        onClick={() => { }}
                    >
                        {concept.button_text.toUpperCase()}
                    </Button>
                </Link>
            </div>
                
        </div>
    )
}

export default ConceptListing;
