/* eslint-disable no-shadow */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useLocation } from 'react-router-dom';
import styles from './LoginPage.module.scss';
import Button from '../../common/components/button/Button';
import Input from '../../common/components/inputs/input/Input';
import Label from '../../common/components/label/Label';
import InputError from '../../common/components/inputs/inputError/InputError';
import FormItem from '../../common/components/formItem/FormItem';
import { useForm, useWatch } from 'react-hook-form';
import AccountService from '../../api/account/AccountService';
import { useToasts } from 'react-toast-notifications';
import { useDispatch, useSelector } from 'react-redux';
import { authenticateUser } from '../../store/authentication/action';
import Loading from '../../common/services/Loading';
import { type Reducers } from '../../store/types';
import colors from 'styles/export/colors.module.scss';
import Logo from 'common/components/logo/Logo';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

enum ScreenType {
    LOGIN,
    RECOVER_PASSWORD,
    SET_PASSWORD,
}

type LoginForm = {
    email: string;
    password: string;
};

type RecoverPasswordForm = {
    email: string;
};

type SetPasswordForm = {
    password: string;
    passwordRepeat: string;
};

const LoginScreen: React.FunctionComponent = () => {
    const { t } = useTranslation();
    const {
        register: registerLogin,
        handleSubmit: handleSubmitLogin,
        formState: { errors: errorsLogin },
        control: ControlLogin
    } = useForm<LoginForm>();

    const {
        register: registerRecoverPassword,
        handleSubmit: handleSubmitRecoverPassword,
        formState: { errors: errorsRecoverPassword },
    } = useForm<RecoverPasswordForm>();

    const {
        register: registerSetPassword,
        handleSubmit: handleSubmitSetPassword,
        formState: { errors: errorsSetPassword },
        watch: watchSetPassword,
    } = useForm<SetPasswordForm>();

    const { addToast } = useToasts();
    const dispatch = useDispatch();
    const isAuthenticated = useSelector<Reducers, boolean>(
        state => state.authentication.isAuthenticated
    );
    const routeLocation = useLocation();
    const query = useQuery();
    const queryToken = query.get('token');
    const queryEmail = query.get('email');
    const [screen, setScreen] = useState<ScreenType>(
        queryToken && queryEmail ? ScreenType.SET_PASSWORD : ScreenType.LOGIN
    );

    const onSubmitLogin = async ({ email, password }: LoginForm) => {
        try {
            Loading.show();
            const response = await AccountService.login({
                email: email,
                password,
            });
            if (response) {
                dispatch(authenticateUser(response.profile, response.token));
            }
            Loading.hide();
        } catch (error) {
            addToast(t('login.error_login'), { appearance: 'error' });
            Loading.hide();
        }
    };

    const onSubmitRecoverPassword = async ({ email }: RecoverPasswordForm) => {
        try {
            Loading.show();
            await AccountService.generateResetPasswordCode({ email: email });

            addToast(t('login.recover_password_email_sent'), {
                appearance: 'success',
            });
            setScreen(ScreenType.LOGIN);

            Loading.hide();
        } catch (error) {
            addToast(t('login.error_recover_password'), {
                appearance: 'error',
            });
            Loading.hide();
        }
    };

    const onSubmitSetPassword = async ({ password }: SetPasswordForm) => {
        try {
            if (!queryToken || !queryEmail || !password) {
                return;
            }

            Loading.show();
            await AccountService.resetPassword({
                token: queryToken,
                email: queryEmail,
                password,
            });

            addToast(t('login.password_reset_success'), {
                appearance: 'success',
            });
            setScreen(ScreenType.LOGIN);

            Loading.hide();
        } catch (error) {
            addToast(t('login.error_recover_password'), {
                appearance: 'error',
            });
            Loading.hide();
        }
    };

    if (isAuthenticated && screen != ScreenType.SET_PASSWORD) {
        return <Navigate to={routeLocation?.state?.from || '/'} />;
    }

    return (

        <div className={styles.container}>
            <div className={styles.content}>
                <Logo logoMode={'dark'} />
                {screen === ScreenType.LOGIN && (
                    <form
                        onSubmit={handleSubmitLogin(onSubmitLogin)}
                        className={styles.loginForm}
                    >
                        <h3 className={styles.loginTitle}>
                            {t('login.welcome')}
                        </h3>

                        <FormItem className={styles.formItem}>
                            <Label>{t('login.email')}</Label>
                            <Input
                                placeholder={t('login.email') ?? ''}
                                {...registerLogin('email', { required: true })}
                            />
                            <InputError error={errorsLogin.email} />
                        </FormItem>

                        <FormItem className={styles.formItem}>
                            <Label>{t('login.password')}</Label>
                            <Input
                                type='password'
                                placeholder={t('login.password') ?? ''}
                                {...registerLogin('password', { required: true })}
                            />
                            <InputError error={errorsLogin.password} />
                        </FormItem>

                        <span
                            className={styles.recoverPasswordLink}
                            onClick={() =>
                                setScreen(ScreenType.RECOVER_PASSWORD)
                            }
                        >
                            {t('login.recover_password_link')}
                        </span>

                        <div className={styles.buttonsContainer}>
                            <Button
                                type='submit'
                                key={'login-button'}
                                opaque={true}
                                color={colors.primary}
                                textColor={colors.basicWhite}
                                onClick={() => { }}
                            >
                                {t('login.login_button')}
                            </Button>
                        </div>

                    </form>
                )}
                {screen === ScreenType.RECOVER_PASSWORD && (
                    <form
                        onSubmit={handleSubmitRecoverPassword(
                            onSubmitRecoverPassword
                        )}
                        className={styles.recoverPasswordForm}
                    >
                        <p className={styles.recoverPasswordTitle}>
                            {t('login.recover_password_title')}
                        </p>
                        <p className={styles.recoverPasswordSubtitle}>
                            {t('login.recover_password_subtitle')}
                        </p>

                        <FormItem className={styles.marginTop2}>
                            <Label>{t('login.email')}</Label>
                            <Input
                                placeholder={t('login.email') ?? ''}
                                {...registerRecoverPassword('email', { required: true })}
                            />
                            <InputError error={errorsRecoverPassword.email} />
                        </FormItem>

                        <div className={styles.buttonsContainer}>
                            <Button
                                type='submit'
                                key={'recover-button'}
                                opaque={true}
                                color={colors.primary}
                                textColor={colors.basicWhite}
                                onClick={() => { }}
                            >
                                {t('login.recover_password_button')}
                            </Button>
                            <Button
                                type='button'
                                key={'cancel-button'}
                                opaque={true}
                                color={colors.default}
                                textColor={colors.basicWhite}
                                className={styles.paddingLeft}
                                onClick={() =>
                                    setScreen(ScreenType.LOGIN)
                                }
                            >
                                {t('common.cancel')}
                            </Button>

                        </div>

                    </form>
                )}
                {screen === ScreenType.SET_PASSWORD && (
                    <form
                        onSubmit={handleSubmitSetPassword(
                            onSubmitSetPassword
                        )}
                        className={styles.setPasswordForm}
                    >
                        <p className={styles.setPasswordTitle}>{t('login.set_password_title')}</p>
                        <p className={styles.setPasswordSubtitle}>{t('login.set_password_subtitle')}</p>

                        <FormItem className={styles.formItem}>
                            <Label>{t('login.password')}</Label>
                            <Input
                                type='password'
                                placeholder={t('login.password') ?? ''}
                                {...registerSetPassword('password', { required: true })}
                            />
                            <InputError
                                error={errorsSetPassword.password}
                            />
                        </FormItem>
                        <FormItem className={styles.formItem}>
                            <Label>{t('login.password_repeat')}</Label>
                            <Input
                                type='password'
                                placeholder={t('login.password_repeat') ?? ''}
                                {...registerSetPassword('passwordRepeat', { required: true, validate: (value) => value === watchSetPassword('password') || (t('common.errors.password_not_match') ?? '') })}
                            />
                            <InputError
                                error={errorsSetPassword.passwordRepeat}
                            />
                        </FormItem>
                        <div className={styles.buttonsContainer}>
                            <Button
                                type='submit'
                                key={'change_password-button'}
                                opaque={true}
                                color={colors.primary}
                                textColor={colors.basicWhite}
                                onClick={() => { }}
                            >
                                {t('login.change_password_button')}
                            </Button>
                        </div>
                    </form>
                )}
            </div>
        </div>

    );
};

export default LoginScreen;
