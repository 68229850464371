import useTitle from 'common/hooks/useTitle';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './ContactsPage.module.scss';
import Header from 'common/components/header/Header';
import Button from 'common/components/button/Button';
import colors from 'styles/export/colors.module.scss';
import Footer from 'common/components/footer/Footer';
import ScrollToTop from 'common/components/scrollToTop/ScrollToTop';
import Container from 'common/components/container/Container';
import SendinBlue from 'common/components/sendinBlue/SendinBlue';
import useIsMobile from 'common/hooks/useIsMobile';
import Menu from 'common/components/menu/Menu';
import { Row } from "react-flexbox-grid";
import { useForm } from "react-hook-form";
import { useToasts } from "react-toast-notifications";
import Logger from "common/services/Logger";
import { LOGGER_LOG_TYPE } from "Config";
import Loading from "common/services/Loading";
import FormItem from 'common/components/formItem/FormItem';
import FormInput from './formInput/FormInput';
import TextArea from './formInput/TextArea';
import ContactsService from 'api/contacts/ContactsService';
import { ContactForm } from 'api/contacts/models/Contact';
import { useNavigate } from 'react-router-dom';
import navigateToElement from 'common/utils/navigateToElement';

interface Contacts {
    contact: string;
    icon: string;
}

const ContactsPage: React.FC = () => {
    const { t, i18n } = useTranslation();
    const { addToast } = useToasts();
    const navigate = useNavigate();
    useTitle(t('contacts.page_title', { appName: t('app.name') }));

    const contacts: Contacts[] = t('contacts.contacts.items', { returnObjects: true }) ?? [];

    const isMobile = useIsMobile();

    const { register, handleSubmit, reset } = useForm<ContactForm>();

    const onClickMainButton = () => {
        const url = t('contacts.header.main_button_url');
        if (!url) {
            return;
        }

        if (url.startsWith('#')) {
            navigateToElement(url);
            return;
        }

        navigate(url);
    }
    
    const onSubmit = async (form: ContactForm) => {
        try {
            Loading.show();
            await ContactsService.registerContact({ ...form, languageIso: i18n.language })
            addToast(t('common.messages.record_save_success'), { appearance: 'success' });            
            Loading.hide();
            reset();
        } catch (error: any) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, "Couldn't get houses list", error);                
            addToast(t('common.messages.record_save_error'), { appearance: 'error' });
            Loading.hide();
        }
    };

    const onError = async () => {
        addToast(t('common.messages.required_fields_empty'), { appearance: 'warning' });        
    };

    return (
        <div className={styles.container}>
            <ScrollToTop />
            <Menu /> 
            <Header
                title={t('contacts.header.title', { interpolation: { escapeValue: false } })}
                subTitle={t('contacts.header.description', { interpolation: { escapeValue: false } })}
                backgroundUrl={t('contacts.header.background_url', { interpolation: { escapeValue: true } })}
                buttons={[
                    <Button
                        key={'main-button'}
                        color={colors.basicWhite}
                        textColor={colors.basicWhite}
                        onClick={onClickMainButton}
                    >                      
                        {t('contacts.header.main_button').toUpperCase()}
                    </Button>
                ]}
            />

            { isMobile ? 
                <>
                    <Container className={styles.sendingBlueMobileForm} id="main-content">
                        <div className={styles.contactForm}>
                                <span className={styles.title}>{t('contacts.form.title').toUpperCase()}</span>
                                <span className={styles.description}>{t('contacts.form.description')}</span>
                                <form onSubmit={handleSubmit(onSubmit, onError)}> 
                                    <Row className={styles.marginNone}>
                                        <FormItem className={styles.widthFull}>
                                            <FormInput 
                                            type="text"
                                            {...register('name', { required: false, maxLength: 100 })}
                                            name="name"
                                            disabled={false}
                                            maxLength={100}
                                            placeholder={t('contacts.form.name') ?? ''}                                
                                            // showRequired={!!errors.title}
                                            />
                                        </FormItem>
                                    </Row>
                                    
                                    <Row className={styles.marginNone}>
                                        <FormItem className={styles.widthFull}>
                                            <FormInput 
                                            type="text"
                                            {...register('email', { required: true, maxLength: 100 })}
                                            name="email"
                                            disabled={false}
                                            maxLength={100}
                                            placeholder={t('contacts.form.email')+ '*' ?? ''}                                
                                            // showRequired={!!errors.title}
                                            />
                                        </FormItem>
                                    </Row>
                                    
                                    <Row className={styles.marginNone}>
                                        <FormItem className={styles.widthFull}>
                                            <FormInput 
                                            type="text"
                                            {...register('phone', { required: false, maxLength: 100 })}
                                            name="phone"
                                            disabled={false}
                                            maxLength={100}
                                            placeholder={t('contacts.form.telephone') ?? ''}                                
                                            // showRequired={!!errors.title}
                                            />
                                        </FormItem>
                                    </Row>

                                    <Row className={styles.marginNone}>
                                        <FormItem className={styles.widthFull}>
                                            <FormInput 
                                            type="text"
                                            {...register('subject', { required: true, maxLength: 100 })}
                                            name="subject"
                                            disabled={false}
                                            maxLength={100}
                                            placeholder={t('contacts.form.subject')+ '*' ?? ''}                                
                                            // showRequired={!!errors.title}
                                            />
                                        </FormItem>
                                    </Row>
                                    <Row className={styles.marginNone}>
                                        <FormItem className={styles.messageInput}>
                                            <TextArea
                                                disabled={false}
                                                name={'message'}
                                                rows={8}
                                                register={register}
                                                maxLength={100}
                                                required={true}
                                                placeholder={t('contacts.form.message')+ '*' ?? ''}   
                                        />
                                        </FormItem>
                                    </Row>

                                    <Row className={`${styles.marginNone} ${styles.buttonRow}`}>
                                        <Button
                                            type='submit'
                                            key={'add-or-update-button'}
                                            opaque={true}
                                            color={colors.greyGreen}
                                            textColor={colors.basicWhite}
                                            onClick={() => { }}
                                            className={styles.sendButton}
                                        >
                                            {t('contacts.form.send_button')}
                                        </Button>
                                    </Row>
                                </form>
                        </div>
                    </Container>
                    <img src={t('contacts.contacts.map_mobile') ?? ''} style={{ width: '100%' }}/>
                    <Container className={styles.contactsContainer}>   
                        <div className={styles.contactsListContainer}>
                            {contacts.map((item, key) => (
                                <div key={key} className={styles.contactItem}>
                                    <img src={item.icon} className={styles.contactIcon}/>
                                    <span>{item.contact}</span>
                                </div>
                            ))}
                        </div>
                    </Container>
                </>
            :
                <div className={styles.contactsContainer} id="main-content">
                    <Container >
                        <div className={styles.flexRow}>
                            <div className={styles.contactsListContainer}>
                                {contacts.map((item, key) => (
                                    <div key={key} className={styles.contactItem}>
                                        <img src={item.icon} className={styles.contactIcon}/>
                                        <span>{item.contact}</span>
                                    </div>
                                ))}
                            </div>
                            <div className={styles.contactForm}>
                                <span className={styles.title}>{t('contacts.form.title').toUpperCase()}</span>
                                <span className={styles.description}>{t('contacts.form.description')}</span>
                                <form onSubmit={handleSubmit(onSubmit, onError)}> 
                                    <Row className={styles.marginNone}>
                                        <FormItem className={styles.widthFull}>
                                            <FormInput 
                                            type="text"
                                            {...register('name', { required: false, maxLength: 100 })}
                                            name="name"
                                            disabled={false}
                                            maxLength={100}
                                            placeholder={t('contacts.form.name') ?? ''}                                
                                            // showRequired={!!errors.title}
                                            />
                                        </FormItem>
                                    </Row>
                                    
                                    <Row className={styles.marginNone}>
                                        <FormItem className={styles.widthFull}>
                                            <FormInput 
                                            type="text"
                                            {...register('email', { required: true, maxLength: 100 })}
                                            name="email"
                                            disabled={false}
                                            maxLength={100}
                                            placeholder={t('contacts.form.email')+ '*' ?? ''}                                
                                            // showRequired={!!errors.title}
                                            />
                                        </FormItem>
                                    </Row>
                                    
                                    <Row className={styles.marginNone}>
                                        <FormItem className={styles.widthFull}>
                                            <FormInput 
                                            type="text"
                                            {...register('phone', { required: true, maxLength: 100 })}
                                            name="phone"
                                            disabled={false}
                                            maxLength={100}
                                            placeholder={t('contacts.form.telephone') + '*' ?? ''}                                
                                            // showRequired={!!errors.title}
                                            />
                                        </FormItem>
                                    </Row>

                                    <Row className={styles.marginNone}>
                                        <FormItem className={styles.widthFull}>
                                            <FormInput 
                                            type="text"
                                            {...register('subject', { required: true, maxLength: 100 })}
                                            name="subject"
                                            disabled={false}
                                            maxLength={100}
                                            placeholder={t('contacts.form.subject')+ '*' ?? ''}                                
                                            // showRequired={!!errors.title}
                                            />
                                        </FormItem>
                                    </Row>
                                    <Row className={styles.marginNone}>
                                        <FormItem className={styles.messageInput}>
                                            <TextArea
                                                disabled={false}
                                                name={'message'}
                                                rows={8}
                                                register={register}
                                                maxLength={100}
                                                required={true}
                                                placeholder={t('contacts.form.message')+ '*' ?? ''}   
                                        />
                                        </FormItem>
                                    </Row>

                                    <Row className={`${styles.marginNone} ${styles.buttonRow}`}>
                                        <Button
                                            type='submit'
                                            key={'add-or-update-button'}
                                            opaque={true}
                                            color={colors.greyGreen}
                                            textColor={colors.basicWhite}
                                            onClick={() => { }}
                                            className={styles.sendButton}
                                        >
                                            {t('contacts.form.send_button')}
                                        </Button>
                                    </Row>
                                </form>
                            </div>
                        </div>
                    </Container>

                    <div className={styles.imageContainer}>
                        <img src={t('contacts.contacts.map') ?? ''} className={styles.img}/>
                    </div>
                </div>
            }
   
            <SendinBlue />
            <Footer/>
        </div>
    );
};

export default ContactsPage;
