import useTitle from "common/hooks/useTitle"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import styles from "./OurHousesPage.module.scss"
import Header from "common/components/header/Header"
import Button from "common/components/button/Button"
import colors from "styles/export/colors.module.scss"
import Footer from "common/components/footer/Footer"
import ScrollToTop from "common/components/scrollToTop/ScrollToTop"
import Container from "common/components/container/Container"
import { LOGGER_LOG_TYPE, MAX_PAGINATION_ITEMS_PER_PAGE } from "Config"
import HousesService from "api/houses/HousesService"
import { HouseDto } from "api/houses/models/HouseDto"
import Loading from "common/services/Loading"
import Logger from "common/services/Logger"
import { useToasts } from "react-toast-notifications"
import HousesList from "common/components/housesList/HousesList"
import Menu from "common/components/menu/Menu"
import { useNavigate } from "react-router-dom"
import navigateToElement from "common/utils/navigateToElement"

const OurHousesPage: React.FC = () => {
	const { t } = useTranslation()
	const navigate = useNavigate();
	
	useTitle(t("our_houses.page_title", { appName: t('app.name') }))

	const [housesToSell, setHousesToSell] = useState<HouseDto[]>([])
	const [housesToFollow, setHousesToFollow] = useState<HouseDto[]>([])
	const [housesSold, setHousesSold] = useState<HouseDto[]>([])
	const { addToast } = useToasts()
	

	useEffect(() => {	
		void getData()
	}, [])

	const getData = async () => {
		try {
			Loading.show()
            
			const housesSell = HousesService.getList({
				page: 1,
				itemsPerPage: MAX_PAGINATION_ITEMS_PER_PAGE,
				finalityType: "SALE",
				orderColumn: 'created_date',
				orderBy: 'desc'
			})
            const housesFollow = HousesService.getList({
				page: 1,
				itemsPerPage: MAX_PAGINATION_ITEMS_PER_PAGE,
				finalityType: "FOLLOW",
				orderColumn: 'created_date',
				orderBy: 'desc'
			})
            const housesSold = HousesService.getList({
				page: 1,
				itemsPerPage: MAX_PAGINATION_ITEMS_PER_PAGE,
				finalityType: "SOLD",
				orderColumn: 'created_date',
				orderBy: 'desc'
			})

			void Promise.all([
				housesSell,
				housesFollow,
				housesSold,
			]).then((result) => {
				setHousesToSell(result[0].items)
				setHousesToFollow(result[1].items)
				setHousesSold(result[2].items)	
			}).then(() => { Loading.hide() })

			
		} catch (error) {
			Logger.error(LOGGER_LOG_TYPE.REQUEST, "Couldn't  get houses list", error)
			addToast(t("common.messages.error_load_info"), { appearance: "error" })
			Loading.hide()
		}
	}

	const onClickMainButton = () => {
		const url = t('our_houses.header.button_url');
		if (!url) {
			return;
		}

		if (url.startsWith('#')) {
			navigateToElement(url);
			return;
		}

		navigate(url);
	}

	return (
		<div className={styles.container}>
			<ScrollToTop />
			<Menu /> 
			<Header
				title={t("our_houses.header.title", {
					interpolation: { escapeValue: false },
				})}
				subTitle={t("our_houses.header.description", {
					interpolation: { escapeValue: false },
				})}
				backgroundUrl={t("our_houses.header.background_url", {
					interpolation: { escapeValue: true },
				})}
				buttons={[
					<Button
						key={"main-button"}
						color={colors.basicWhite}
						textColor={colors.basicWhite}
						onClick={onClickMainButton}
					>
						{t("our_houses.header.main_button").toUpperCase()}
					</Button>,
				]}
			/>

			<Container className={styles.housesListContainer} id="main-content">
				{ housesToSell.length > 0 && <HousesList houses={housesToSell} title={ t('our_houses.houses_listing.in_sell', { returnObjects: true })} /> }
				{ housesToFollow.length > 0 && <HousesList houses={housesToFollow} title={ t('our_houses.houses_listing.to_follow', { returnObjects: true })} className={styles.houseTypeContainer}/> }
				{ housesSold.length > 0 && <HousesList houses={housesSold} title={ t('our_houses.houses_listing.sold', { returnObjects: true })} className={styles.houseTypeContainer}/> }
			</Container>

			<Footer />
		</div>
	)
}

export default OurHousesPage
