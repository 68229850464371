import React from 'react';
import { useTranslation } from 'react-i18next';
import Container from '../container/Container';
import styles from './SendinBlue.module.scss';
import DivHtml from '../divHtml/DivHtml';
import useIsMobile from 'common/hooks/useIsMobile';
import { useForm } from "react-hook-form";
import { MailForm } from 'api/contacts/models/Contact';
import { LOGGER_LOG_TYPE } from 'Config';
import ContactsService from 'api/contacts/ContactsService';
import Loading from 'common/services/Loading';
import { useToasts } from "react-toast-notifications";
import Logger from "common/services/Logger";
import { ReactComponent as SendIcon } from 'assets/svgs/send.svg'

const SendinBlue: React.FC<any> = () => {
    const { t } = useTranslation();
    const isMobile = useIsMobile();
    const { register, handleSubmit, reset } = useForm<MailForm>();
    const { addToast } = useToasts();

    const onSubmit = async (form: MailForm) => {
        try {
            Loading.show();            
            await ContactsService.addMail(form);
            addToast(t('common.messages.record_save_success'), { appearance: 'success' });  
            reset();
            Loading.hide();
        } catch (error: any) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, "Couldn't get houses list", error);                
            addToast(t('common.messages.record_save_error'), { appearance: 'error' });
            Loading.hide();
        }
    };

    const onError = async () => {
        addToast(t('common.messages.required_fields_empty'), { appearance: 'warning' });        
    };

    return (
        <Container className={styles.container}>
            <div className={styles.sendBlueContainer} 
                style={{ backgroundImage: isMobile ? `url(${t('sendinBlue.background_image_mobile' ?? '')})` : `url(${t('sendinBlue.background_image' ?? '')})` }}>
                <div className={styles.textContainer} >
                    <DivHtml className={styles.title} html={t('sendinBlue.title', { interpolation: { escapeValue: false } }).toUpperCase()} />
                    <div className={styles.description}>{t('sendinBlue.description')}</div>
                </div>           
                <form onSubmit={handleSubmit(onSubmit, onError)} className={styles.form}>
                    <div className={styles.inputBar}>
                        <div className={styles.webInput}>
                            <input
                                id='input'
                                type="text"
                                autoComplete="off"
                                placeholder={t('sendinBlue.input_placeholder') ?? ''}
                                {...register('email', { required: true, maxLength: 100 })}
                                name="email"
                                pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                                disabled={false}
                                maxLength={100}
                            />

                            <span>
                                <button className={styles.inputButton} type='submit'>
                                    <SendIcon className={styles.icon}/>
                                    {t('sendinBlue.button_text')}
                                </button>
                            </span>
                        </div>
                    </div>
                </form>
            </div>    
        </Container>
    );
}

export default SendinBlue
