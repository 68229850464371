import { UserProfile } from "api/account/models/UserProfile";
import HousesService from "api/houses/HousesService";
import { HouseDto, HouseTypologyDto } from "api/houses/models/HouseDto";
import { TypologyDto } from "api/houses/models/TypologyDto";
import { TypologySearchCriteria } from "api/houses/models/TypologySearchCriteria";
import Button from "common/components/button/Button";
import FormItem from "common/components/formItem/FormItem";
import ImageInput from "common/components/inputs/imageInput/ImageInput";
import Input from "common/components/inputs/input/Input";
import InputError from "common/components/inputs/inputError/InputError";
import SelectInput from "common/components/inputs/SelectInput";
import Label from "common/components/label/Label";
import QuestionYesNo from "common/components/questionYesNo/QuestionYesNo";
import RenderSVG from "common/components/renderSVG/RenderSVG";
import Loading from "common/services/Loading";
import Logger from "common/services/Logger";
import { SelectValueLabel } from "common/types/SelectValueLabel";
import { FINALITY_TYPE, LOGGER_LOG_TYPE } from "Config";
import { useEffect, useState } from "react";
import { Col, Row } from "react-flexbox-grid";
import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { initAuthentication } from "store/authentication/action";
import { Reducers } from "store/types";
import colors from 'styles/export/colors.module.scss';
import styles from './HousePage.module.scss';
import { MediaItemDto } from 'api/common/models/MediaItemDto';
import CustomFile from "common/models/CustomFile";
import { FaTrash } from "react-icons/fa";
import newGuid from "common/utils/newGuid";
import ToggleSwitch from "common/components/toggleSwitch/ToggleSwitch";
import SummernoteController from 'common/components/summernoteController/SummernoteController';

enum Types {
    create = 'create',
    edit = 'edit',
    details = 'details',
};

function HousePage(): JSX.Element {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { addToast } = useToasts();

    const { id, type } = useParams<{ id?: string; type: Types }>();
    const userProfile = useSelector<Reducers, UserProfile | null>(state => state.authentication.profile);

    const { register, handleSubmit, setValue, control, formState, watch } = useForm<HouseDto>();
    const { fields, append, remove } = useFieldArray({ control, name: "phases" });

    const errors = formState.errors;
    const [houseToRemove, setHouseToRemove] = useState<HouseDto>({
        id: '',
        title: '',
        description: '',
        details: '',
        location: '',
        price: 0,
        link3d: '',
        finalityType: '',
        photoCover: null,
        typologies: [],
        phases: [],
        attachments: []
    });

    const [typologies, setTypologies] = useState<TypologyDto[]>([]);

    const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
    const [isDetails, setIsDetails] = useState<boolean>(
        !!(type && type === Types.details)
    );

    const [previewPhotoCover, setPreviewPhotoCover] = useState<string | null>(null);

    const finalityTypes: SelectValueLabel[] = [
        { value: FINALITY_TYPE.SALE, label: t('houses.finaly_type.' + FINALITY_TYPE.SALE as any) },
        { value: FINALITY_TYPE.FOLLOW, label: t('houses.finaly_type.' + FINALITY_TYPE.FOLLOW as any) },
        { value: FINALITY_TYPE.SOLD, label: t('houses.finaly_type.' + FINALITY_TYPE.SOLD as any) }
    ];

    const [attachments, setAttachments] = useState<MediaItemDto[]>([]);
    const [attachmentsToRemove, setAttachmentsToRemove] = useState<string[]>([]);

    useEffect(() => {
        setIsDetails(!!(type && type === Types.details));
        void getData();
    }, [id, type]);

    const getData = async () => {
        try {
            Loading.show();

            const [typologies, house] = await Promise.all([
                HousesService.getListTypologies({} as TypologySearchCriteria),
                id ? HousesService.get(id) : null
            ]);

            setTypologies(typologies);
            if (house) {
                setValues(house);
                buildAttachments(house);
            }
            buildTypologies(house, typologies);

            Loading.hide();
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t get house', error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }
    };

    const setValues = (house: HouseDto) => {
        setHouseToRemove(house);
        setValue('id', house.id);
        setValue('title', house.title);
        setValue('description', house.description);
        setValue('details', house.details);
        setValue('location', house.location);
        setValue('price', house.price);
        setValue('link3d', house.link3d);
        setValue('photoCover', house.photoCover);
        setValue('finalityType', house.finalityType);
        setValue('phases', house.phases);
        setPreviewPhotoCover(house.mediaUrl ?? '');
    };

    const buildTypologies = (house: HouseDto | null, typologies: TypologyDto[]) => {
        const list: HouseTypologyDto[] = [];
        typologies.forEach(t => {
            const exist = (house?.typologies || []).find(x => x.typologyId == t.id);
            if (exist) {
                list.push(exist);
            } else {
                list.push({
                    typologyId: t.id ?? '',
                    icon: t.icon,
                    name: t.name,
                    value: '',
                    position: t.position
                });
            }
        });
        setValue('typologies', list);
    }

    const buildAttachments = (house: HouseDto) => {
        const files: MediaItemDto[] = [];

        house.attachments?.forEach(att => {
            files.push({
                tempFile: null,
                id: att.id ?? newGuid(),
                url: att.url ?? '',
                fileName: att.fileName ?? ''
            });
        });

        setAttachments(files);
        setValue('attachments', files);
    }

    const onSubmit = async (house: HouseDto) => {
        try {
            Loading.show();

            const filesToSend: CustomFile[] = [];
            attachments.forEach(x => x.tempFile && filesToSend.push(x.tempFile));

            if (house.photoCover && house.photoCover.tempFile) {
                filesToSend.push(house.photoCover.tempFile);
            }

            if (id && id != null) {
                house.attachmentsToRemove = attachmentsToRemove;
                await HousesService.update(house, filesToSend);
                addToast(t('common.messages.record_save_success'), { appearance: 'success' });
                if (id === userProfile?.id) { dispatch(initAuthentication(() => true) as any); }
                navigateTo();
            } else {
                await HousesService.create(house, filesToSend);
                addToast(t('common.messages.record_save_success'), { appearance: 'success' });
                navigateTo();
            }
        } catch (error: any) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, "Couldn't get houses list", error);
            addToast(t('common.messages.record_save_error'), { appearance: 'error' });
            Loading.hide();
        }
    };

    const onError = async () => {
        addToast(t('common.messages.required_fields_empty'), { appearance: 'warning' });
    };

    const showRemoveItemDialog = async () => {
        setShowRemoveModal(true);
    };

    const onCancelRemove = () => {
        setShowRemoveModal(false);
    };

    const [myTime, setMyTime] = useState(new Date());

    useEffect(() => {
        var timerID = setInterval(() => tick(), 1000);

        return () => clearInterval(timerID);
    });

    function tick() {
        setMyTime(new Date());
    }

    const removeItem = async () => {
        if (id && id != null) {
            try {
                Loading.show();
                await HousesService.remove(houseToRemove);
                addToast(t('common.messages.record_delete_success'), { appearance: 'success' });
                Loading.hide();
                navigateTo();
            } catch (error) {
                setShowRemoveModal(false);
                addToast(t('common.messages.record_delete_error'), { appearance: 'error' });
                Loading.hide();
            }
        }
    };

    const navigateTo = (typeUrl?: string, idToGo?: string) => {
        if (typeUrl) {
            navigate(`/admin/houses/${typeUrl}/${idToGo}`);
            setIsDetails(typeUrl === Types.details);
        } else {
            navigate(`/admin/houses`);
        }
    };

    const onChooseImage = (file: CustomFile, dataUrl: string) => {
        file.containerName = undefined;
        const files = [...attachments, { tempFile: file, id: newGuid(), url: dataUrl, fileName: '' }];
        setAttachments(files);
    };

    const onDeleteAttachment = async (id: string) => {
        setAttachmentsToRemove([...attachmentsToRemove, id]);
        const files = attachments.filter(x => x.id != id);
        setAttachments(files);
    };

    const onChooseImageCover = (file: CustomFile, dataUrl: string) => {
        file.containerName = 'cover';
        setValue('photoCover', { tempFile: file, id: newGuid(), url: dataUrl, fileName: '' });
        setPreviewPhotoCover(dataUrl);
    };

    const onDeleteImageCover = (url: string) => {
        var media = attachments.filter(x => x.url === url);
        if (media && media.length > 0) {
            onDeleteAttachment(media[0].id);
        }
        setValue('photoCover', null);
        setPreviewPhotoCover(null);
    };

    return (
        <div className={styles.container}>

            <form onSubmit={handleSubmit(onSubmit, onError)}>
                <Row>
                    <Col xs={12} lg={10}>
                        <FormItem key={`sn_title_${type}_${id}`}>
                            <Label>{t('houses.title')}</Label>
                            <SummernoteController
                                className={styles.summernote}
                                name={'title'}
                                placeholder={(!isDetails ? (t('common.write_here') ?? '') : '')}
                                isDisabled={isDetails}
                                rules={{ required: true, maxLength: 250 }}
                                control={control} />
                            <InputError error={errors.title} maxLength={250} />
                        </FormItem>
                    </Col>
                    <Col xs={12} lg={10}>
                        <FormItem key={`sn_description_${type}_${id}`}>
                            <Label>{t('houses.description')}</Label>
                            <SummernoteController
                                className={styles.summernote}
                                name={'description'}
                                placeholder={(!isDetails ? (t('common.write_here') ?? '') : '')}
                                isDisabled={isDetails}
                                control={control} />
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} lg={10}>
                        <FormItem key={`sn_details_${type}_${id}`}>
                            <Label>{t('houses.details')}</Label>
                            <SummernoteController
                                className={styles.summernote}
                                name={'details'}
                                placeholder={(!isDetails ? (t('common.write_here') ?? '') : '')}
                                isDisabled={isDetails}
                                control={control} />
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} lg={10}>
                        <FormItem>
                            <Label required={!!errors.location}>{t('houses.location') + (!isDetails ? '*' : '')}</Label>
                            <Input
                                type="text"
                                {...register('location', { required: true, maxLength: 990 })}
                                name="location"
                                maxLength={990}
                                disabled={isDetails}
                                showRequired={!!errors.location}
                            />
                            <InputError error={errors.location} maxLength={990} />
                        </FormItem>
                    </Col>
                    <Col xs={12} lg={5}>
                        <FormItem>
                            <Label required={!!errors.location}>{t('houses.price')}</Label>
                            <Input
                                type="number"
                                {...register('price', { maxLength: 50 })}
                                name="price"
                                disabled={isDetails}
                                maxLength={50}
                                step={0.01}
                                min={0}
                                className={styles.textRight}
                            />
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} lg={5}>
                        <FormItem>
                            <Label>{t('houses.link3d')}</Label>
                            <Input
                                type="text"
                                {...register('link3d', { maxLength: 999 })}
                                name="link3d"
                                disabled={isDetails}
                            />
                        </FormItem>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} lg={5}>
                        <FormItem>
                            <Label required={!!errors.finalityType}>{t('houses.finality') + (!isDetails ? '*' : '')}</Label>
                            <SelectInput
                                optionsList={finalityTypes}
                                disabled={isDetails}
                                placeholder={t('houses.finality_placeholder') ?? ''}
                                required={true}
                                name="finalityType"
                                control={control}
                                showRequired={!!errors.finalityType}
                            />
                            <InputError error={errors.finalityType} />
                        </FormItem>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} lg={10} className={styles.sectionTitle}>
                        {t('houses.typologies')}
                    </Col>
                </Row>

                <Row>
                    {typologies.map((x, i) => {
                        return <Col style={{ display: 'flex', alignItems: 'center', backgroundColor: colors.sponsorsGrey, padding: '1rem' }}
                            key={i} xs={12} lg={10}>
                            <Col xs={1} lg={1}>
                                <RenderSVG item={x.icon} />
                            </Col>
                            <Col xs={2} lg={2}>
                                {t('houses.typology_types.' + x.name as any)}
                            </Col>
                            <Col xs={9} lg={9}>
                                <Input
                                    type="text"
                                    {...register(`typologies.${i}.value`, { maxLength: 250 })}
                                    disabled={isDetails} />
                            </Col>
                        </Col>
                    })}
                </Row>

                {typologies.length == 0 && <Row>
                    <Col xs={12} lg={10} style={{ backgroundColor: colors.sponsorsGrey, padding: '1rem', textAlign: 'center', fontWeight: 'bold' }}>
                        {t('common.no_results')}
                    </Col>
                </Row>}

                <Row>
                    <Col xs={12} lg={10} className={styles.sectionTitle}>
                        {t('houses.phases')}
                    </Col>
                </Row>

                <Row>
                    <Col style={{ display: 'flex', alignItems: 'center', backgroundColor: colors.sponsorsGrey, padding: '1rem' }} xs={12} lg={10}>
                        <Col xs={5} lg={5}>
                            {t('houses.phase_name') + (!isDetails ? '*' : '')}
                        </Col>
                        <Col xs={3} lg={3}>
                            {t('common.position') + (!isDetails ? '*' : '')}
                        </Col>
                        <Col xs={3} lg={3}>
                            {t('houses.phase_complete')}
                        </Col>
                        <Col xs={1} lg={1}>
                        </Col>
                    </Col>

                    {fields.map((x, i) => {
                        return <Col style={{ display: 'flex', alignItems: 'center', backgroundColor: colors.sponsorsGrey, padding: '0 1rem 1rem 1rem' }}
                            key={i} xs={12} lg={10}>
                            <Col xs={5} lg={5}>
                                <Input
                                    type="text"
                                    {...register(`phases.${i}.name`, { maxLength: 250, required: true })}
                                    disabled={isDetails}
                                    maxLength={250}
                                />
                                <InputError error={errors.phases ? errors.phases[i]?.name : undefined} maxLength={250} />
                            </Col>
                            <Col xs={3} lg={3}>
                                <Input
                                    type="number"
                                    defaultValue={x.position}
                                    {...register(`phases.${i}.position`, { maxLength: 50, required: true })}
                                    disabled={isDetails}
                                    maxLength={50}
                                    step={1}
                                    min={0}
                                />
                                <InputError error={errors.phases ? errors.phases[i]?.position : undefined} maxLength={50} />
                            </Col>
                            <Col xs={3} lg={3}>
                                <ToggleSwitch
                                    value={x.complete}
                                    color={colors.limegreen}
                                    disabled={isDetails}
                                    onChange={(check) => setValue(`phases.${i}.complete`, check)} />
                            </Col>
                            <Col xs={1} lg={1}>
                                {!isDetails && <Button
                                    type='button'
                                    opaque={true}
                                    color={colors.danger}
                                    textColor={colors.basicWhite}
                                    onClick={() => remove(i)}
                                ><FaTrash size={16} /></Button>}
                            </Col>
                        </Col>
                    })}
                </Row>

                {fields.length == 0 && <Row>
                    <Col xs={12} lg={10} style={{ backgroundColor: colors.sponsorsGrey, padding: '1rem', textAlign: 'center', fontWeight: 'bold' }}>
                        {t('common.no_results')}
                    </Col>
                </Row>}

                {!isDetails && <Row>
                    <Col xs={12} lg={10} style={{ marginTop: '1rem', paddingLeft: 0 }}>
                        <Button
                            type='button'
                            opaque={true}
                            color={colors.default}
                            textColor={colors.basicWhite}
                            onClick={() => append({ name: "", position: 1, complete: false })}
                        >{t('houses.add_phase')}</Button>
                    </Col>
                </Row>}

                <Row>
                    <Col xs={12} md={10} className={styles.sectionTitle}>
                        {t('houses.photo_cover')}
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} md={10}>
                        <div className={styles.photoCoverLoad}>
                            <ImageInput
                                key={'photoCover'}
                                onChooseFile={onChooseImageCover}
                                previewUrl={previewPhotoCover}
                                onDelete={onDeleteImageCover}
                                disabled={isDetails}
                                cropImage={true}
                                circularCrop={false}
                                roundDisplay={false}
                                rectangularShape={false}
                                forceAspectRatio={true}
                            />
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col xs={12} lg={10} className={styles.sectionTitle}>
                        {t('houses.photos')}
                    </Col>
                </Row>

                {!isDetails && <Row>
                    <Col xs={12} lg={10}>
                        <div className={styles.photoLoad}>
                            <ImageInput
                                onChooseFile={onChooseImage}
                                previewUrl={null}
                                disabled={isDetails}
                                circularCrop={false}
                                forceAspectRatio={true}
                                rectangularShape={true}
                            />
                        </div>
                    </Col>
                </Row>}

                <Row>
                    {attachments.filter(x => x.url != houseToRemove.mediaUrl).map((x, i) => {
                        return <Col style={{ paddingLeft: 0 }} key={i} xs={3} lg={3}>
                            <div className={styles.previewContainer}>
                                <img src={x.url} className={styles.previewImage} />
                                <div className={styles.previewOverlay}>
                                    {!isDetails && <div className={`${styles.deleteButton}`} onClick={() => onDeleteAttachment(x.id)}>
                                        <FaTrash size={16} />
                                    </div>}
                                </div>
                            </div>
                        </Col>
                    })}
                </Row>

                {isDetails && attachments.filter(x => x.url != houseToRemove.mediaUrl).length == 0 && <Row>
                    <Col xs={12} lg={10} style={{ backgroundColor: colors.sponsorsGrey, padding: '1rem', textAlign: 'center', fontWeight: 'bold' }}>
                        {t('common.no_results')}
                    </Col>
                </Row>}

                <Row>
                    <Col xs={12} lg={10} className={styles.buttonsContainer}>
                        {type !== Types.details &&
                            <Button
                                type='submit'
                                key={'add-or-update-button'}
                                opaque={true}
                                color={colors.primary}
                                textColor={colors.basicWhite}
                                className={styles.paddingLeft}
                                onClick={() => { }}
                            >
                                {type === Types.create ? t('common.add') : t('common.save')}
                            </Button>
                        }
                        {type === Types.details && <Button
                            type='button'
                            key={'update-button'}
                            opaque={true}
                            color={colors.primary}
                            textColor={colors.basicWhite}
                            className={styles.paddingLeft}
                            onClick={() => navigateTo('edit', houseToRemove.id)}
                        >
                            {t('common.edit')}
                        </Button>
                        }
                        {type === Types.details && <Button
                            type='button'
                            key={'delete-button'}
                            opaque={true}
                            color={colors.danger}
                            textColor={colors.basicWhite}
                            className={styles.paddingLeft}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                showRemoveItemDialog();
                            }}
                        >
                            {t('common.delete')}
                        </Button>
                        }
                        <Button
                            type='button'
                            key={'cancel-button'}
                            opaque={true}
                            color={colors.default}
                            textColor={colors.basicWhite}
                            className={styles.paddingLeft}
                            onClick={() => navigateTo()}
                        >
                            {t('common.cancel')}
                        </Button>
                    </Col>
                </Row>
            </form>
            <QuestionYesNo
                onNo={onCancelRemove}
                onYes={removeItem}
                isVisible={showRemoveModal}
                message={t('common.messages.remove_record_with_ident', {
                    name: houseToRemove?.title ?? '',
                })}
            />
        </div>
    );
};

export default HousePage;