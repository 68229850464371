import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './HousesListing.module.scss';
import Loading from 'common/services/Loading';
import { HouseDto } from 'api/houses/models/HouseDto';
import HousesService from 'api/houses/HousesService';
import Logger from 'common/services/Logger';
import { LOGGER_LOG_TYPE } from 'Config';
import { Link } from 'react-router-dom';
import Button from 'common/components/button/Button';
import colors from 'styles/export/colors.module.scss';
import { useToasts } from 'react-toast-notifications';
import HousesList from 'common/components/housesList/HousesList';

const HousesListing: React.FC = () => {
    const { t } = useTranslation();
    const [houses, setHouses] = useState<HouseDto[]>([]);
    const { addToast } = useToasts();

    const housesListingTranlations = t('home.houses', { returnObjects: true })

    useEffect(() => {
        void getData()
    }, [])

    const getData = async () => {
        try {
            Loading.show();
            const result = await HousesService.getList({ page: 1, itemsPerPage: 3 });
            setHouses(result.items);
            Loading.hide();
        } catch (error) {
            Logger.error(LOGGER_LOG_TYPE.REQUEST, 'Couldn\'t  get houses list', error);
            addToast(t('common.messages.error_load_info'), { appearance: 'error' });
            Loading.hide();
        }
    };

    return (
        <>
            <HousesList houses={houses} title={ housesListingTranlations }/>
            <div className={styles.houseButtonContainer}>
                <Link to="/our_houses" className={styles.houseButton}>
                    <Button
                        color={colors.basicGrayDarker}
                        textColor={colors.basicGrayDarker}                               
                        onClick={() => {}}
                        >
                        {t('home.houses.button_text').toUpperCase()}
                    </Button>
                </Link> 
            </div>
        </>
    )
}

export default HousesListing;