import React from 'react';
import SidebarMenu from '../sidebarMenu/SideBarMenu';
import styles from './AuthenticatedLayout.module.scss';
import { useSelector } from 'react-redux';
import { Reducers } from 'store/types';


export interface SidebarItem {
    text: string;
    url: string;
}

interface Props {
    children: React.ReactNode
}

const AuthenticatedLayout: React.FunctionComponent<Props> = ({ children }: Props) => {
    const showSidebar = useSelector<Reducers, boolean>(state => state.sideBar.open);

    return (
            <div className= {styles.container} >
                <div className={`${styles.contentContainer} ${showSidebar ? styles.overflowCon : null}`}>
                    {children }
                </div>
                {showSidebar && <SidebarMenu />}
            </div>
    );
};

export default AuthenticatedLayout;
