import React from 'react';
import { useTranslation } from 'react-i18next';
import Footer from 'common/components/footer/Footer';
import Container from 'common/components/container/Container';
import PageSection from 'common/components/pageSection/PageSection';
import styles from './PolicyPage.module.scss';
import ScrollToTop from 'common/components/scrollToTop/ScrollToTop';
import useTitle from 'common/hooks/useTitle';
import PHtml from 'common/components/divHtml/PHtml';
import Logo from 'common/components/logo/Logo';

interface TranslationText {
    title: string;
    content: string;
    subtitles?: TranslationText[];
}

const PolicyPage: React.FC<any> = () => {
    const { t } = useTranslation();
    useTitle(t('policy.page_title', { appName: t('app.name') }));

    const texts: TranslationText[] = t('policy.texts', { returnObjects: true }) ?? [];

    return (
        <div className={`${styles.container}`}>
            <ScrollToTop />
            <Container>
                <div className={styles.logoContainer}>
                    <Logo logoMode={'dark'} />
                </div>
                <PageSection
                    title={t('policy.title')}
                    className={styles.section}
                    contentClassName={styles.content}
                    colorMode={'dark'}
                >
                    {texts.map((t, i) => (
                        <div key={i} className={styles.lightItem}>
                            <h1>{t.title}</h1>
                            <PHtml html={t.content} />
                            {(t.subtitles ?? []).map((st, ii) => (
                                <div key={ii}>
                                    <h2 className={styles.h2}>{st.title}</h2>
                                    <PHtml html={st.content} />
                                </div>
                            ))}
                        </div>
                    ))}
                </PageSection>
            </Container>
            <Footer/>
        </div>
    );
};

export default PolicyPage;
